// desktop_building_description

.desktop_building_description {
  margin: 0 0 $spacerxxl2 0;
  padding: 0 0 0 $desktop-gutter;
  background-color: $white;

  &__title {
    @extend .title_heading_m;
    margin: 0 0 $spacers 0;
    padding: 0;
  }
  &__text {
    margin: 0 0 $spacerxxs2 0;
    padding: 0;
    position: relative;
    overflow: hidden;
    max-height: 140px;
    white-space: pre-line;
    &--open {
      max-height: none;
    }
    &__overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 25px;
      background: transparent url('../../public/assets/img/bg_read_more.png')
        repeat-x bottom left;
    }
  }
  &__more {
    @extend .text_s;
    margin: 0;
    padding: 0;
    color: $primary;
    cursor: pointer;
  }
}
