// desktop_pagination

.desktop_pagination {
  margin: $spacerm 0 $spacerm 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  &__item {
    flex: 0 0 auto;
    @extend .title_number_xs;
    @extend %font-inter-bold;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    border: 1px solid $secondary;
    background-color: $white;
    color: $secondary;
    text-align: center;
    line-height: 32px;
    margin: 0 $spacerxxs 0 $spacerxxs;
    cursor: pointer;
    &--active {
      background-color: $secondary;
      color: $white;
    }
    &--first {
      margin-right: $spacers;
    }
    &--last {
      margin-left: $spacers;
    }
    &--control {
      background-color: $white;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      &--next {
        background-image: url('../../public/assets/img/pagination_arrow_next.svg');
        &--disabled {
          cursor: not-allowed;
          border-color: $grey3;
          background-image: url('../../public/assets/img/pagination_arrow_next_disabled.svg');
        }
      }
      &--prev {
        background-image: url('../../public/assets/img/pagination_arrow_prev.svg');
        &--disabled {
          cursor: not-allowed;
          border-color: $grey3;
          background-image: url('../../public/assets/img/pagination_arrow_prev_disabled.svg');
        }
      }
      span {
        display: none;
      }
    }
  }
}
