.blockchain-badge {
  padding: 0px 8px;
  /* Opacity/Interactive/Dark/900/10% */
  background: rgba(13, 0, 104, 0.1);
  border-radius: 4px;
  margin-left: 8px;
  display: inline-block;
  max-width: fit-content;
  cursor: pointer;

  @include smartphone {
    margin-left: 0px;
    display: block;
  }

  &--background {
    background-position: 0 50%;
    background-repeat: no-repeat;
    background-image: url('../../public/assets/img/polygon_logo.svg');
    background-size: 18px 18px;
    padding-left: 24px;
  }

  &--text {
    color: #0d0068;

    /* Interactive/Button/S */
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;

    /* identical to box height, or 167% */
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }
}
