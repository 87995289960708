// desktop_questions

.desktop_questions {
  margin: 0 0 $spacerxl 0;
  // padding: $spacerxs2 0 $spacerxxl $desktop-gutter;
  border-bottom: 1px solid $grey4;
  &__title {
    @extend .title_heading_m;
    margin: 0 0 $spacerxs2 0;
    padding: 0;
  }
  &__toggle {
    float: right;
    border: none;
    outline: none;
    background: url('../../public/assets/img/ico_arrow_up_grey.svg') no-repeat
      50% 50%;
    width: 32px;
    height: 32px;
    &--opened {
      background-image: url('../../public/assets/img/ico_arrow_down_grey.svg');
    }
    span {
      display: none;
    }
  }

  &__call {
    background: $white url('../../public/assets/img/bg_pattern.svg') repeat 0 0;
    margin: 0;
    padding: $spacers $spacerxxl $spacerm $spacers;
    width: 100%;
  }

  &__call_title {
    @extend .title_heading_s;
  }

  &__write {
    @extend .title_heading_s;
    padding: $spacerxs2;
  }

  &__info {
    padding: $spacerxs2;
    @include tablet {
      display: none;
    }

    &__list {
      list-style: none;
      margin: 18px 0 0 0;
      padding: 0;
      text-align: left;

      &__item {
        @extend .text_s;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: 0 50%;
        margin: 15px $spacerl 0 0;
        padding-left: 25px;

        &:last-child {
          margin-bottom: 0;
        }

        &--hour {
          background-image: url('../../public/assets/img/menu_footer_hour.svg');
        }

        &--tel {
          background-image: url('../../public/assets/img/menu_footer_phone.svg');
          text-decoration: underline;
        }
      }
    }
  }

  .contact_form {
    padding: 0 $spacerxs2;
  }

  .booking-unavailable {
    margin-top: -40px;
    margin-bottom: 35px;

    @include smartphone {
      margin-top: 0;
      margin-bottom: 35px;
    }
  }
}
