// toggle

.wrg-toggle {
  margin-top: 4px;
  position: relative;

  &__input {
    display: none;
  }

  &__container {
    width: 51px;
    height: 30px;
    padding: 0;
    border-radius: 30px;
    background-color: $primary;
  }

  &__circle {
    transition: all 0.25s ease;
    position: absolute;
    top: 1px;
    left: 21px;
    width: 28px;
    height: 27px;
    border: 1px solid $grey2;
    border-radius: 50%;
    background-color: $white;
    z-index: 1;
  }

  &__unchecked {
    .wrg-toggle__circle {
      left: 1px;
    }
    .wrg-toggle__container {
      background-color: $grey3;
    }
  }

  &__disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
