// desktop_text_block

.desktop_text_block {
  padding: $spacerxxl3 ($desktop-gutter * 2) $spacerxs2 $desktop-gutter;
  background-color: $white;
  @include m-desktop {
    padding-left: $desktop-small-gutter;
    padding-right: $desktop-small-gutter;
  }
  @include tablet {
    padding: $spacerm $desktop-xsmall-gutter;
  }
  &__title {
    @extend .title_display_xxl;
    margin: 0 0 $spacerxs2 0;
    padding: 0;
  }
  &__subtitle {
    @extend .title_subheading_m;
    margin: 0 0 $spacerxs2 0;
    padding: 0;
    color: $text;
  }
  &__text {
    @extend .text_m;
    margin: 0;
    padding: 0;
    &:after {
      content: '';
      display: block;
      height: 2px;
      width: 72px;
      background-color: $grey2;
      margin-top: $spacerm;
    }
  }
}
