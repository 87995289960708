// building_descriptions

.building_descriptions {
  padding: $spacerxs2;
  &__link_to_full_description {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: $primary;
    cursor: pointer;
    a:link,
    a:visited {
      text-decoration: none;
      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  }
}
