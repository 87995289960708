// info_block

.info_block {
  background-color: $background8;
  border-radius: 2px;
  padding: $spacerxs2;
  &__text {
    @extend .text_xxs;
    color: $secondary;
    margin: 0 0 $spacerxxs 0;
  }
  &__list {
    @extend .text_xxs;
    margin: 0 0 0 $spacers;
    padding: 0;
    color: $secondary;
  }
}
