// desktop_property_detail

.desktop_property_detail {
  display: flex;
  flex-direction: column;
  // padding: $spacerm $desktop-gutter;
  padding: 0px 120px 16px 120px;
  padding-right: $layout_aside-width + 25px;
  background-color: $grey6;
  margin-bottom: $spacerm;
  &__title {
    @extend .title_number_l;
    margin: 0 0 $spacerxs 0;
    padding: 0;
    color: $black_figma;
  }
  &__code {
    @extend .text_s;
    margin: 0 0 $spacerxxs2 0;
    padding: 0;
    color: $grey2;
  }
  &__zone {
    @extend .title_number_xs;
    margin: 0 0 $spacerxxs2 0;
    padding: 0;
    color: $text2;
    text-transform: uppercase;
  }
  &__address {
    @extend .text_s;
    background: transparent url('../../public/assets/img/ico_pointer_grey.svg')
      no-repeat 0 50%;
    margin: 5px 0 10px 0;
    padding-left: 22px;
    color: $black_figma;
    font-style: normal;
  }
  &__info {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    &__item {
      @extend .text_s;
      color: $black_figma;
      min-width: auto;
      margin-right: 10px;
      padding: 0 10px 0 25px;
      border-right: 1px solid $grey5;
      background-color: transparent;
      background-position: 0 50%;
      background-repeat: no-repeat;
      &:last-child {
        border-right: none;
      }
      &--room {
        background-image: url('../../public/assets/img/ico_building_room_grey.svg');
      }
      &--bath {
        background-image: url('../../public/assets/img/ico_building_bath_grey.svg');
      }
      &--floor {
        background-image: url('../../public/assets/img/ico_building_floor_grey.svg');
      }
      &--dimension {
        background-image: url('../../public/assets/img/ico_building_dimension_grey.svg');
      }
    }
  }
}
