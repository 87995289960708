// desktop_building_visit_cta

.desktop_building_visit_cta {
  margin: 0 0 $spacerxxl2 0;
  padding: 0 0 0 $desktop-gutter;
  &__title {
    @extend .title_heading_m;
    margin: 0 0 $spacers 0;
    padding: 0;
  }
  &__list {
    @extend .clear_fix;
    margin: 0;
    padding: 0;
    list-style: none;
    &__item {
      float: left;
      width: calc(25% - #{$spacerxs2});
      height: 160px;
      background-color: $grey6;
      background-image: url('../../public/assets/img/list_bg_sx.svg'),
        url('../../public/assets/img/list_bg_dx.svg');
      background-position: top left, top right;
      background-repeat: no-repeat, no-repeat;
      margin: 0 $spacerxs2 $spacerxs2 0;
      padding: $spacerxs2;
      border-radius: 4px;
      cursor: pointer;
      @include m-desktop {
        width: calc(50% - #{$spacerxs2});
      }
      &:last-child {
        margin-right: 0;
      }
      &--selected {
        border: 1px solid $primary;
      }
      &__text {
        @extend %font-inter-bold;
        font-size: 12px;
        line-height: 20px;
        color: $grey1;
        margin: 0 0 $spacerxs2 0;
        padding: 50px 0 0 0;
        text-transform: uppercase;
        text-align: center;
        background-color: transparent;
        background-position: 50% 0;
        background-repeat: no-repeat;
        &--in_person_visit {
          background-image: url('../../public/assets/img/building_visit.svg');
        }
        &--virtual_visit {
          background-image: url('../../public/assets/img/building_virtual.svg');
        }
        &--360_tour_visit {
          background-image: url('../../public/assets/img/building_street_view.svg');
        }
        &--open_house_visit {
          background-image: url('../../public/assets/img/building_open_house.svg');
        }
      }
      &__button {
        @extend .button_tertiary;
        @extend .button_tertiary--small;
        @extend %font-inter-bold;
        display: block;
        margin: auto;
        text-align: center;
      }
    }
  }
}
