// building_characteristics

.property_characteristics {
  padding: $spacerxs2;
  margin: 0 0 $spacerxs2 0;
  border-bottom: 1px solid $grey4;

  &__list {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    div {
      break-inside: avoid;
    }

    @include only-desktop {
      background-color: $white;
      margin: 0 0 $spacerxxl2 0;
      padding: $spacerxs2 0 $spacerxxl $desktop-gutter;
      display: block;
      column-count: 2;
    }
  }

  &--type2 {
    border-bottom: none;
  }

  &__title {
    @extend .title_display_s;
    margin: 5px 0 10px 0;
    color: $black_figma;
    @include only-desktop {
      font-size: 2em;
      margin: 0.67em 0;
      padding-left: 120px;
    }
  }
  &__row {
    display: flex;
    margin: 13px 0 13px 0;
    .info {
      flex: 1 1 50%;
      // margin-left: $spacerxs2;
      // margin-right: $spacerxs2;
      color: $black_figma;

      &__text {
        @extend .title_subheading_xxs;
        margin: 0;
      }

      &__value {
        @extend .title_number_s;
        font-weight: bold;
        margin: 0;
      }
    }
  }
}
