// desktop_breadcrumb

.desktop_breadcrumb {
  padding: 16px 0;
  //padding: $spacerxs2 $desktop-gutter;
  @include tablet {
    padding: $spacerm $desktop-small-gutter;
  }
  @include smartphone {
    padding: $spacerm $desktop-xsmall-gutter;
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    &__item {
      flex: 0 0 auto;
      @extend .text_xxs;
      margin-right: $spacerxxs2;
      a:link,
      a:visited {
        color: $primary;
      }
      &::after {
        content: '/';
        margin-left: $spacerxxs2;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &--active {
        color: $grey1;
      }
    }
  }
  &--type2 {
    padding-top: 0;
    padding-left: 0;
  }
}
