// bid_block

.bid_block {
  padding: $spacers $spacerxs2 $spacerxs2 $spacerxs2;
  background-color: $white;
  &__title {
    @extend .title_heading_m;
  }
  &__list {
    list-style: none;
    margin: 0 0 $spacers 0;
    padding: 0;
    &__item {
      border-bottom: 1px solid $grey4;
      padding: $spacerxs2 0 $spacerxs2 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__label {
        @extend .title_subheading_xxs;
        margin: 0;
        padding: 0;
        color: $grey2;
        display: flex;
        align-items: center;
        button {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
      &__minimum_raise {
        @extend .spinner;
        &__value {
          @extend .spinner__value;
          margin-right: 40px;
        }
      }
    }
  }
}
