.immoprefooter {
  width: 100%;
  background: #f5f5ff;
  padding-top: 12px;
  padding-bottom: 12px;
  @include tablet {
    padding-left: 56px;
  }
  @include smartphone {
    padding-left: 24px;
  }
  &__title {
    @extend %font-inter-regular;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #2824e5;
    display: flex;
    margin-bottom: 12px;
    justify-content: center;
    align-content: center;
    cursor: pointer;

    :nth-child(2) {
      margin-left: 12px;
      margin-right: 12px;
    }
    @include tablet {
      justify-content: start;
      :first-child {
        display: none;
      }

      :nth-child(2) {
        margin-left: 0;
      }
    }
  }

  &__arrow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 6.5px;
    width: 11px;
    align-self: center;
  }

  &__grid {
    transition: 0.4s;
    padding-bottom: 16px;
    &--closed {
      transition: 0.4s;
      padding-bottom: 0px;
      display: none;
    }
    display: flex;
    justify-content: space-around;
    @include tablet {
      flex-direction: column;
    }
    @include smartphone {
      flex-direction: column;
    }
    &__column {
      &__title {
        @extend %font-inter-bold;
        font-size: 16px;
        letter-spacing: 0.006em;
        line-height: 32px;
      }
      a {
        @extend %font-inter-regular;
        line-height: 24px;
        color: #131223;
        font-weight: 400;
        font-size: 14px;
        @include tablet {
          line-height: 32px;
        }
      }
      @include tablet {
        text-align: left;
        margin-bottom: 28px;
      }
    }
  }
}
