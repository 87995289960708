.desktop_information_presenter {
  &__title {
    @extend .title_heading_s;
  }
  &__definition_list {
    @extend .definition_list;
    margin-bottom: $spacers;
  }
  &__definition_term {
    @extend .definition_list__term;
  }
  &__definition_details {
    @extend .definition_list__details;
  }
  &__definition_title {
    @extend .definition_list__title;
  }
  &__separator {
    display: block;
    margin-bottom: 24px;
    border: none;
    border-bottom: 1px solid $grey2;
    background-color: transparent;
  }
  &__definition_button {
    @extend .button_tertiary;
    @extend .button_tertiary--small;
    @extend .button_ico;
    @extend .button_ico--edit;

    float: right;
  }

  &__definition_privacy {
    margin: 10px 20px 24px 0;
    float: left;
  }

  &__definition_privacy_text {
    @extend .text_s;
    margin-left: 6px;
  }

  &__button_primary {
    @extend .button_primary;
    @extend .button_primary--small;
    margin-right: $spacerm;
  }
  &__button_secondary {
    @extend .button_tertiary;
    @extend .button_tertiary--small;
  }
}
