.newsletter {
  @include tablet {
    width: 100%;
  }

  &__button {
    @extend .button_primary;
    margin-top: $spacers;
  }

  &__success {
    @extend %font-inter-medium;
    margin-top: $spacers;
    margin-bottom: $spacers;
    color: $white;
  }
}
