// header

.header {
  top: 0;
  left: 0;
  width: 100%;

  &__active {
    position: fixed;
    z-index: 20000;
    height: 100%;
    background-color: $menu_background_grey;
    top: 84px;

    @include tablet {
      top: 0;
    }
    @include smartphone {
      top: 0;
    }
  }

  .user_menu {
    position: absolute;
  }

  // material-ui
  .MuiButtonBase-root {
    z-index: -1;
  }
}
