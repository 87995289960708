// desktop_building_visit_cta3

.desktop_building_visit_cta3 {
  margin: 0;
  padding: 0;
  &__title {
    @extend .title_heading_m;
    margin: 0 0 $spacers 0;
    padding: 0;
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    &__item {
      flex: 1 1 auto;
      background-color: $grey6;
      background-image: url('../../public/assets/img/list_bg_sx2.svg'),
        url('../../public/assets/img/list_bg_dx2.svg');
      background-position: top left, top right;
      background-repeat: no-repeat, no-repeat;
      margin: 0 $spacerxxs2 0 0;
      padding: $spacerxxs2;
      border-radius: 4px;
      height: 48px;
      cursor: pointer;
      @include xs-smartphone {
        padding: $spacerxs2;
        height: auto;
      }
      &:last-child {
        margin-right: 0;
      }
      &__text {
        @extend %font-inter-bold;
        font-size: 12px;
        line-height: 20px;
        color: $grey1;
        margin: 7px 0 $spacerxs2 0;
        padding: 0 0 0 30px;
        text-transform: uppercase;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: 0 50%;
        background-size: 20px;
        @include xs-smartphone {
          margin: 0px;
          margin-top: -5px;
        }
        &--centered {
          text-align: center;
        }
        &--in_person_visit {
          background-image: url('../../public/assets/img/building_visit.svg');
        }
        &--virtual_visit {
          background-image: url('../../public/assets/img/building_virtual.svg');
        }
        &--360_tour_visit {
          background-image: url('../../public/assets/img/building_street_view.svg');
        }
        &--open_house_visit {
          background-image: url('../../public/assets/img/building_open_house.svg');
        }
      }
      &__button {
        float: right;
        width: 32px;
        height: 32px;
        cursor: pointer;
        outline: none;
        border: none;
        background: transparent
          url('../../public/assets/img/ico_arrow_right_blue.svg') no-repeat 50%
          50%;
        span {
          display: none;
        }
      }
      &--selected {
        border: 1px solid $primary;
      }
    }
  }
}
