// page_feedback

.page_feedback {
  $self: &;
  background: $grey6 url('../../public/assets/img/modal_bg.svg') repeat 0 0;
  padding: $spacerxs2;
  position: relative;
  &--without-bg {
    background: none;
    padding: 0;
  }
  &--with_footer_button {
    padding-bottom: $spacerxxl4;
  }
  &--feedback_ok {
    #{$self}__title {
      background: transparent url('../../public/assets/img/feedback_ok.svg') no-repeat 50% $spacerl;
      padding-top: 150px;
    }
  }
  &__title {
    @extend .title_display_l;
    text-align: center;
    margin: 0 0 $spacerxs2 0;
    padding: 0;
  }
  &__text {
    @extend .title_subheading_xs;
    text-align: center;
    margin: 0 0 $spacerxs2 0;
    padding: 0;
    &--type2 {
      @extend %font-inter-regular;
      color: $black_figma;
    }
  }

  &__button {
    @extend .button_primary;
    position: absolute;
    bottom: $spacerxs2;
    left: $spacerxs2;
    width: calc(100% - #{$spacerxs2} * 2);
  }
}
