// modal

.modal {
  position: relative;
  background-color: $white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: $spacerxl $spacerxs2 0 $spacerxs2;
  &__close_button {
    position: absolute;
    top: $spacerxs2;
    right: $spacerxxs2;
    border: none;
    outline: none;
    width: 40px;
    height: 40px;
    background: transparent url('../../public/assets/img/modal_close.svg')
      no-repeat 50% 50%;
    span {
      display: none;
    }
  }
  &__title {
    @extend .title_heading_m;
    margin: 0 0 $spacers 0;
    padding: 0;
  }
  &--type2 {
    background: $background4 url('../../public/assets/img/modal_bg.svg') repeat
      0 0;
    min-height: calc(100vh - #{$spacerxxl+2});
  }
}

// modal content
.bid_done {
  padding-bottom: $spacerxs2;
  &__title {
    @extend .title_number_l;
    margin: 0 0 $spacers 0;
    padding: 0;
    text-align: center;
  }
  &__sub_title {
    @extend .title_subheading_xs;
    text-align: center;
    margin: 0;
    padding: 0;
    em {
      display: block;
      font-style: normal;
      color: $grey2;
    }
  }
  &__price {
    @extend .title_number_l;
    text-align: center;
    margin-bottom: 120px;
    span {
      background-color: $background5;
      padding: $spacerxxs2 $spacers;
    }
  }
  &__text {
    @extend .text_xs;
    color: $black_figma;
    text-align: center;
    margin: 0 0 $spacerxs2 0;
    padding: 0;
  }
}

.bid_confirm {
  &__price {
    @extend .title_number_l;
    color: $secondary;
    margin: 0 0 $spacers 0;
    padding: 0;
    text-align: center;
  }
  &__text {
    @extend .text_xs;
    color: $black_figma;
    text-align: center;
    margin: 0 0 $spacers 0;
    padding: 0;
  }
  &__actions {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: $spacerm;
    button {
      margin-left: $spacerxxs2;
      margin-right: $spacerxxs2;
      width: 150px;
    }
  }
}

.feedback {
  background-color: transparent;
  background-position: 50% $spacerxl;
  background-repeat: no-repeat;
  padding-top: 170px;
  &--ok {
    background-image: url('../../public/assets/img/feedback_ok.svg');
  }
}
