// Atoms

// Titles
.title_display_xxl {
  @extend %font-spacegrotesk-bold;
  font-size: 48px;
  line-height: 62px;
  color: $text;
}

.title_display_xl {
  @extend %font-spacegrotesk-bold;
  font-size: 32px;
  line-height: 58px;
  color: $text;
}

.title_display_l {
  @extend %font-spacegrotesk-bold;
  font-size: 32px;
  line-height: 40px;
  color: $text;
}

.title_display_m {
  @extend %font-spacegrotesk-bold;
  font-size: 24px;
  line-height: 32px;
  color: $text;
}

.title_display_s {
  @extend %font-spacegrotesk-bold;
  font-size: 20px;
  line-height: 28px;
  color: $text;
}

.title_display_xs {
  @extend %font-spacegrotesk-bold;
  font-size: 16px;
  line-height: 24px;
  color: $text;
}

.title_number_l {
  @extend %font-spacegrotesk-medium;
  font-size: 32px;
  line-height: 40px;
  color: $text;
}

.title_number_m {
  @extend %font-spacegrotesk-medium;
  font-size: 24px;
  line-height: 32px;
  color: $text;
}

.title_number_s {
  @extend %font-spacegrotesk-medium;
  font-size: 16px;
  line-height: 24px;
  color: $text;
}

.title_number_xs {
  @extend %font-spacegrotesk-medium;
  font-size: 14px;
  line-height: 16px;
  color: $text;
}

.title_heading_xl {
  @extend %font-inter-bold;
  font-size: 48px;
  line-height: 58px;
  color: $text;
}

.title_heading_l {
  @extend %font-inter-bold;
  font-size: 32px;
  line-height: 40px;
  color: $text;
}

.title_heading_m {
  @extend %font-inter-bold;
  font-size: 24px;
  line-height: 32px;
  color: $text;
}

.title_heading_s {
  @extend %font-inter-bold;
  font-size: 20px;
  line-height: 28px;
  color: $text;
}

.title_heading_xs {
  @extend %font-inter-bold;
  font-size: 16px;
  line-height: 24px;
  color: $text;
}

.title_heading_xxs {
  @extend %font-inter-bold;
  font-size: 14px;
  line-height: 20px;
  color: $text;
}

.title_heading_xxxs {
  @extend %font-inter-bold;
  font-size: 12px;
  line-height: 18px;
  color: $text;
}

.title_subheading_m {
  @extend %font-inter-medium;
  font-size: 24px;
  line-height: 36px;
  color: $grey1;
}

.title_subheading_s {
  @extend %font-inter-medium;
  font-size: 20px;
  line-height: 32px;
  color: $grey1;
}

.title_subheading_xs {
  @extend %font-inter-medium;
  font-size: 16px;
  line-height: 24px;
  color: $grey1;
}

.title_subheading_xxs {
  @extend %font-inter-medium;
  font-size: 14px;
  line-height: 20px;
  color: $grey1;
}

// Text
.text_m {
  @extend %font-inter-regular;
  font-size: 20px;
  line-height: 32px;
  color: $grey1;
}

.text_s {
  @extend %font-inter-regular;
  font-size: 16px;
  line-height: 28px;
  color: $grey1;
}

.text_xs {
  @extend %font-inter-regular;
  font-size: 14px;
  line-height: 24px;
  color: $grey1;
}

.text_xxs {
  @extend %font-inter-regular;
  font-size: 12px;
  line-height: 20px;
  color: $grey1;
}

.text_error {
  color: $state3;
}

.text_warning {
  color: $state2;
}

.text_success {
  color: $state1;
}

.text_success2 {
  color: $state4;
}

// Buttons
.button_primary {
  @extend %font-inter-bold;
  background-color: $primary;
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  border-radius: 4px;
  color: $white;
  cursor: pointer;
  padding: 11px $spacerxs2;
  &:hover {
    background-color: $primary_hover;
  }
  &--type2 {
    background-color: $white;
    color: $primary;
    &:hover {
      background-color: $white;
      opacity: 0.4;
    }
  }
  &--type3 {
    background-color: $accent;
    &:hover {
      background-color: $accent_hover;
    }
  }
  &--type4 {
    @extend .button_primary--type2;
    border: 1px solid $primary;
  }
  &--small {
    font-size: 14px;
    line-height: 24px;
    padding: $spacerxxs $spacerxxs2;
  }
  &.disable,
  .button_primary_disabled,
  &[disabled] {
    color: $grey2;
    background-color: $grey6;
    cursor: not-allowed;
    &:hover {
      opacity: 1;
    }
  }
}

//TODO debito tecnico usare una classe unica di disabled, fix per il modale di material che con il button disabilitato non prende gli eventi.
.button_primary_disabled {
  color: $grey2;
  background-color: $grey6;
  cursor: not-allowed;
  &:hover {
    background-color: $grey6;
    color: $grey2;
    opacity: 1;
  }
}

.button_secondary {
  @extend %font-inter-bold;
  background-color: transparent;
  border: 1px solid $secondary;
  outline: none;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  border-radius: 4px;
  color: $secondary;
  cursor: pointer;
  padding: 11px $spacerxs2;
  &:hover {
    border-color: $secondary_hover;
    color: $secondary_hover;
  }
  &--type2 {
    border-color: $white;
    color: $white;
    &:hover {
      background-color: #c8c7d6;
      border-color: $white;
      color: $white;
    }
  }
  &--small {
    font-size: 14px;
    line-height: 24px;
    padding: $spacerxxs $spacerxxs2;
  }
  &.disable,
  &[disabled] {
    color: $grey2;
    background-color: transparent;
    border-color: $white;
    opacity: 0.4;
    cursor: not-allowed;
    &:hover {
      opacity: 0.4;
    }
  }
}

.button_tertiary {
  @extend %font-inter-bold;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  color: $primary;
  cursor: pointer;
  padding: 11px $spacerxs2;
  &:hover {
    color: $primary_hover;
  }
  &--type2 {
    color: $white;
    &:hover {
      color: $grey3;
    }
  }
  &--small {
    font-size: 14px;
    line-height: 24px;
    padding: $spacerxxs $spacerxxs2;
  }
  &.disable,
  &[disabled] {
    color: $grey2;
    background-color: transparent;
    border-color: $white;
    opacity: 0.4;
    cursor: not-allowed;
    &:hover {
      opacity: 0.4;
    }
  }
}

@mixin button_ico($align: left, $text: yes, $ico: dropdown) {
  background-repeat: no-repeat;
  text-align: left;
  @if $align==right {
    background-position: 86% 50%;
    padding-right: 48px;
    padding-left: $spacerxs2;
  } @else {
    background-position: 18px 50%;
    padding-left: 50px;
  }
  @if $text==no {
    padding: 20px 20px;
    background-position: 50% 50%;
    vertical-align: middle;
    span {
      display: none;
    }
  } @else {
    span {
      display: inline;
    }
  }
  @if $ico==placeholder {
    background-image: url('../public/assets/img/placeholder_button_ico.svg');
  }
  @if $ico==hamburger {
    background-image: url('../public/assets/img/ico_menu_hamburger.svg');
  }
  @if $ico==back {
    background-image: url('../public/assets/img/ico_menu_back.svg');
  }
  @if $ico==edit {
    background-image: url('../public/assets/img/ico_edit.svg');
  }
  @if $ico==close {
    background-image: url('../public/assets/img/ico_menu_close.svg');
  }
  @if $ico==close_white {
    background-image: url('../public/assets/img/ico_menu_close_white.svg');
  }
  @if $ico==close_blue {
    background-image: url('../public/assets/img/ico_close_blue.svg');
  }
  @if $ico==user {
    background-image: url('../public/assets/img/ico_user.svg');
  }
  @if $ico==search {
    background-image: url('../public/assets/img/ico_menu_search.svg');
  }
  @if $ico==arrow_left_white {
    background-image: url('../public/assets/img/ico_arrow_left_white.svg');
  }
  @if $ico==arrow_down_blu_small {
    background-image: url('../public/assets/img/ico_arrow_down_blue.svg');
  }
  @if $ico==hearth_blu {
    background-image: url('../public/assets/img/ico_hearth_blu.svg');
  }
  @if $ico==hearth_blu_filled {
    background-image: url('../public/assets/img/ico_hearth_blu_filled.svg');
  }
  @if $ico==share_blu {
    background-image: url('../public/assets/img/ico_share_blu.svg');
  }
  @if $ico==info_blu {
    background-image: url('../public/assets/img/ico_info_blu.svg');
  }
  @if $ico==play_blu {
    background-image: url('../public/assets/img/ico_play_blu.svg');
  }
}

.button_ico {
  @include button_ico;
  &--placeholder {
    @include button_ico(left, yes, placeholder);
  }
  &--placeholder2 {
    @include button_ico(right, yes, placeholder);
  }
  &--placeholder3 {
    @include button_ico(left, no, placeholder);
  }
  &--edit {
    @include button_ico(right, yes, edit);
  }
  &--hamburger {
    @include button_ico(left, no, hamburger);
  }
  &--back {
    @include button_ico(left, no, back);
  }
  &--close {
    @include button_ico(left, no, close);
  }
  &--close_white {
    @include button_ico(left, no, close_white);
  }
  &--close_blue {
    @include button_ico(left, no, close_blue);
  }
  &--user {
    @include button_ico(left, no, user);
  }
  &--search {
    @include button_ico(left, no, search);
  }
  &--hearth_blu {
    @include button_ico(left, no, hearth_blu);
  }
  &--share_blu {
    @include button_ico(left, no, share_blu);
  }
  &--info_blu {
    @include button_ico(left, no, info_blu);
  }
  &--play_blu {
    @include button_ico(left, no, play_blu);
  }
}

.button_link {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.04em;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: underline;
  text-align: right;
  a:link,
  a:visited {
    text-decoration: underline;
    &:hover,
    &:active {
      text-decoration: none;
    }
  }
}

// Form
.input_style1 {
  @extend %font-inter-regular;
  outline: none;
  background-color: $white;
  border: 1px solid $grey3;
  border-radius: 4px;
  color: $text;
  padding: 0 12px 0 12px;
  font-size: 14px;
  line-height: 24px;
  height: 48px;
  &::placeholder {
    color: $grey2;
  }
  &:hover {
    border-color: $form_hover;
  }
  &:focus {
    border-color: $text;
  }
  &.disable,
  &[disabled] {
    color: $grey2;
    background-color: $grey6;
    border: none;
    cursor: not-allowed;
  }
  &--error {
    border-color: $state3;
    color: $state3;
  }
  &--warning {
    border-color: $state2;
    color: $state2;
  }
  &--success {
    border-color: $state1;
    color: $state1;
  }
  &--password {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: calc(100% - 40px) !important;
  }
}

.select_style1 {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: $text;
  padding: 0.6em 1.8em 0.5em 0.8em;
  height: 48px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid $grey3;
  border-radius: 4px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: $white;
  background-image: url('../public/assets/img/select_arrow.svg'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  outline: none;
  &:hover {
    border-color: $form_hover;
  }
  &:focus {
    border-color: $text;
  }
  &::-ms-expand {
    display: none;
  }
  option {
    font-weight: normal;
  }
  &.disable,
  &[disabled] {
    color: $grey2;
    background-color: $grey6;
    border: none;
    cursor: not-allowed;
  }
  &--error {
    border-color: $state3;
    color: $state3;
  }
  &--warning {
    border-color: $state2;
    color: $state2;
  }
  &--success {
    border-color: $state1;
    color: $state1;
  }
}

.textarea_style1 {
  @extend %font-inter-regular;
  outline: none;
  background-color: $white;
  border: 1px solid $grey3;
  border-radius: 4px;
  color: $text;
  padding: 0 12px 0 12px;
  font-size: 14px;
  line-height: 24px;
  &--fluid {
    width: 100%;
  }

  &::placeholder {
    color: $grey2;
    padding-top: 5px;
  }
}

.assistive_text {
  @extend .text_xs;
  color: #0d0068;
  margin: 4px 0;
  padding: 0 0 0 12px;
  background-color: transparent;
  background-position: 12px 50%;
  background-repeat: no-repeat;
  &--disabled {
    color: $grey2;
  }
  &--error {
    color: $state3;
    background-image: url('../public/assets/img/field_error.svg');
    padding-left: 30px;
  }
  &--warning {
    color: $state2;
    background-image: url('../public/assets/img/field_warning.svg');
    padding-left: 30px;
  }
  &--success {
    color: $state1;
    background-image: url('../public/assets/img/field_success.svg');
    padding-left: 30px;
  }
}

.ico_pwd {
  width: 40px;
  height: 48px;
  margin-top: -48px;
  position: relative;
  left: calc(100% - 40px) !important;
  border: 1px solid $grey3;
  border-radius: 0 4px 4px 0;
  border-left: none;
  background-position-y: center;
  background-position-x: calc(50% - 1px);
  cursor: pointer;
  background-repeat: no-repeat;
  &--show {
    background-image: url('../public/assets/img/ico_pwd_show.svg');
  }
  &--hide {
    background-image: url('../public/assets/img/ico_pwd_hide.svg');
  }
}

// button_chips
.button_chips {
  @include box-shadow(z4);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  background: $white;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  color: $primary;
  background-color: $white;
  border: 1px solid transparent;
  max-width: 400px;
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &:hover {
    background-color: $chip_selected;
  }
  &__open {
    border: 1px solid $primary;
  }
  &--type2 {
    background-color: $chip_selected;
  }
}

@mixin chips_ico($align: left, $text: yes, $ico: dropdown) {
  background-repeat: no-repeat;
  text-align: left;
  @if $align==right {
    background-position: calc(100% - $spacerxs2) 50%;
    padding-right: 48px;
    padding-left: $spacerxs2;
  } @else {
    background-position: 18px 50%;
    padding-left: 50px;
  }
  @if $text==no {
    padding: 20px 20px;
    background-position: 50% 50%;
    vertical-align: middle;
    span {
      display: none;
    }
  } @else {
    span {
      display: inline;
    }
  }
  @if $ico==placeholder {
    background-image: url('../public/assets/img/placeholder_button_ico.svg');
  }
  @if $ico==arrow_down_blue {
    background-image: url('../public/assets/img/ico_arrow_down_blue.svg');
  }
  @if $ico==arrow_up_blue {
    background-image: url('../public/assets/img/ico_arrow_up_blue.svg');
  }
  @if $ico==close_blue {
    background-image: url('../public/assets/img/ico_close_blue.svg');
  }
  @if $ico==arrow_right_blue {
    background-image: url('../public/assets/img/ico_arrow_right2_blue.svg');
  }
  @if $ico==add_blue {
    background-image: url('../public/assets/img/ico_add_blue.svg');
  }
}

.chips_ico {
  @include chips_ico;
  &--placeholder {
    @include chips_ico('right', yes, placeholder);
  }
  &--arrow_down_blue {
    @include chips_ico('right', yes, arrow_down_blue);
  }
  &--arrow_up_blue {
    @include chips_ico('right', yes, arrow_up_blue);
  }
  &--close_blue {
    @include chips_ico('right', yes, close_blue);
  }
  &--arrow_right_blue {
    @include chips_ico('right', yes, arrow_right_blue);
  }
  &--add_blue {
    @include chips_ico('right', yes, add_blue);
  }
}

.definition_list {
  &__term {
    @extend .title_subheading_xxs;
    color: $static_page_info_color;
    margin-bottom: 2px;
  }
  &__details {
    @extend .title_heading_xs;
    margin-left: 0;
  }
  &__title {
    @extend .title_heading_xxs;
    margin-left: 0;
    font-weight: bold;
  }
}

// button_marker
.button_marker {
  @include box-shadow(z6);
  @extend %font-spacegrotesk-bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: $white;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  border: 1px solid transparent;
  border-radius: 28px;
  height: 33px;
  min-width: 33px;

  > span {
    padding-left: 8px;
    padding-right: 3px;
  }

  :hover {
    cursor: pointer;
  }

  &__icon {
    width: 13px;
    height: 13px;
    transform: rotate(-45deg);
    border-radius: 2px;
  }

  &--primary {
    color: $text;
    background-color: $white;

    .button_marker__icon {
      background-color: $secondary;
    }

    &.button_marker--active {
      background-color: $primary;
      color: $white;

      .button_marker__icon {
        background-color: $white;
      }
    }
  }

  &--secondary {
    color: $text;
    background-color: $white;

    .button_marker__icon {
      background-color: $state3;
    }

    &.button_marker--active {
      color: $text;
      border-color: $primary;
    }
  }
}
