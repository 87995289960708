// property_detail2

.property_detail2 {
  @extend .property_detail;
  padding: 0 $spacerxs2 $spacerxs2 $spacerxs2;
  background-color: $white;
  &__title {
    @extend .property_detail__title;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 $spacerxxs2 0;
    color: $text;
    &--spaceTop {
      margin-top: $spacerxs2;
    }
  }

  &__address {
    @extend .property_detail__address;
    @extend .text_xxs;
    &--fixed-height {
      min-height: 40px;
    }
  }
  &__zone {
    @extend .title_number_xs;
    margin: 0 0 $spacerxxs2 0;
    padding: 0;
    color: $text2;
    text-transform: uppercase;
  }
  &__info {
    @extend .property_detail__info;
    &__item {
      @extend .property_detail__info__item;
    }
    // TODO Debito tecnico, media query specifica
    @media only screen and (max-width: 385px) {
      &__item {
        padding: 0 4px 0 24px !important;
        font-size: $spacerxs;
      }
    }
  }
  &__subtitle {
    @extend .title_subheading_xxs;
    margin: $spacerxs2 0 $spacerxxs 0;
    padding: 0;
    color: $grey2;
  }
  &__text {
    @extend .title_number_s;
    margin: 0;
    padding: 0;
  }
}
