// tab

.tab_list_wrapper {
  margin: $main_tab_container-margin-top 0 0 0;
  border-bottom: 1px solid $grey4;
}

.tab_list {
  @include hide-scrollbar;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  overflow-x: auto;
  position: relative;
  top: 1px;
  z-index: 999;

  &__item {
    @extend %font-inter-bold;
    cursor: pointer;
    flex: 1 1 auto;
    margin: 0 20px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    border: 0px;
    color: $grey2;
    min-width: 130px;
    &--active {
      color: $primary;
      border-bottom: 1px solid $primary;
    }
  }
}
