// summary2

.summary2_wrapper {
  max-width: 340px;
}

.summary2 {
  border: 1px solid $grey3;
  border-radius: 4px;
  background-color: $white;
  &__header {
    background-color: $grey6;
    background-position: bottom 50% right $spacerxs2;
    background-repeat: no-repeat;
    background-size: 16px;
    padding: 0 $spacerxs2 0 $spacerxs2;
    &--visit {
      background-image: url('../../public/assets/img/building_visit.svg');
    }
    &--virtual {
      background-image: url('../../public/assets/img/building_virtual.svg');
    }
    &__date,
    &__time {
      @extend .title_number_xs;
      letter-spacing: 0.8px;
      display: inline-block;
      color: $text2;
      margin: 0 20px 0 0;
      padding: 0 0 0 20px;
      background: transparent url('../../public/assets/img/ico_auction_calendar_grey.svg') no-repeat
        0 50%;
    }
    &__time {
      background-image: url('../../public/assets/img/ico_auction_clock_grey.svg');
    }
  }
  &__article {
    padding: $spacerxs2;
    &__code,
    &__visit {
      @extend .text_xxs;
      color: $grey2;
      margin: 0;
      padding: 0;
    }
    &__title {
      @extend .title_heading_xs;
      margin: $spacerxs 0 $spacerxs 0;
      padding: 0;
    }
    &__address,
    &__link {
      @extend .text_xxs;
      font-style: normal;
      color: $text;
      margin: 0;
      padding: 0 0 0 15px;
      background: transparent url('../../public/assets/img/ico_address_11_black.svg') no-repeat 0
        50%;
    }
    &__link {
      background-image: url('../../public/assets/img/ico_link_11_black.svg');
    }
  }
  &__footer {
    border-top: 1px solid $grey3;
    padding: $spacerxs2;
    display: flex;
    &__button {
      @extend %font-inter-bold;
      flex: 1 1 auto;
      &--delete {
        @extend .button_secondary;
        @extend .button_secondary--small;
        margin-right: $spacerxs2;
      }
      &--modify {
        @extend .button_primary;
        @extend .button_primary--small;
      }
    }
  }
}
