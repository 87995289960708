.feedback_box {
  padding: 0 $desktop-gutter;
  max-width: 1200px;
  margin: 0 auto;
  height: auto;
  min-height: 280px;

  @include tablet {
    padding-left: $desktop-small-gutter;
    padding-right: $desktop-small-gutter;
    padding-bottom: $spacerxxl;
  }
  @include smartphone {
    padding-left: $desktop-xsmall-gutter;
    padding-right: $desktop-xsmall-gutter;
    padding-bottom: $spacerxxl;
  }

  &__title {
    grid-area: title;
    line-height: $spacerm;
    text-align: center;
    margin-bottom: $spacerm;
    @extend .title_heading_l;
    @include tablet {
      text-align: left;
      margin-bottom: $spacerm;
      padding-left: 0 !important;
      font-size: 24px;
    }
  }

  &__list {
    padding-left: 0px !important;
    @include tablet {
      padding: 0;
    }
    &__container {
      &--fadetext {
        animation: fadeIn linear $fadetime;
        -webkit-animation: fadeInSafari linear $fadetime;
        -moz-animation: fadeIn linear $fadetime;
        -o-animation: fadeIn linear $fadetime;
        -ms-animation: fadeIn linear $fadetime;
        animation-iteration-count: infinite;
      }

      &--expanded {
        animation: none;
      }

      @keyframes fadeIn {
        0% {
          opacity: 0;
          -webkit-opacity: 0;
        }
        10% {
          opacity: 1;
          -webkit-opacity: 1;
        }
        90% {
          opacity: 1;
          -webkit-opacity: 1;
        }
        100% {
          opacity: 0;
          -webkit-opacity: 0;
        }
      }

      @-webkit-keyframes fadeInSafari {
        0% {
          -webkit-opacity: 0;
        }
        5% {
          -webkit-opacity: 1;
        }
        95% {
          -webkit-opacity: 1;
        }
        100% {
          -webkit-opacity: 0;
        }
      }

      width: 100%;
      display: grid;
      grid-template-columns: 25% 1fr;
      grid-template-rows: 1fr;
      grid-template-areas:
        '. title'
        'avatar description';
      margin-left: 48px;
      @include tablet {
        grid-template-areas:
          'avatar'
          'description';
        grid-template-columns: 1fr;
        justify-items: start;
        grid-row-gap: 12px;
        margin-left: 0 !important;
      }
      &__avatar {
        display: grid;
        grid-template-rows: $spacerm 1fr;
        grid-template-areas:
          'title'
          'info';
        grid-area: avatar;
        align-items: start;
        @include tablet {
          align-items: start;
          grid-template-columns: 1fr;
        }
        &__img {
          grid-area: image;
          width: $spacerxxl2;
          height: $spacerxxl2;
          @include tablet {
            padding-top: 6px;
          }
        }
        &__title {
          color: $primary;
          font-family: $font-inter-bold;
          font-size: $spacers;
          grid-area: title;
        }
        &__info {
          color: $grey2;
          font-size: $spacerxs2;
          grid-area: info;
        }
      }
      &__description {
        grid-area: description;
        font-size: $spacerxs2;
        line-height: 28px;
        font-style: italic;
        max-width: 600px;
        padding-left: $spacerm;
        @include tablet {
          width: 100%;
          padding-left: 0;
        }
      }
      &__cta {
        font-size: 14px;
        font-style: normal;
        font-family: $font-inter-bold;
        text-transform: uppercase;
        color: $grey2;
        margin-top: $spacerxs;
        cursor: pointer;
        &__a {
          color: red;
        }
        &__a:hover {
          color: $primary_hover;
        }
      }
    }
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
