// desktop_pressrelease

.desktop_pressrelease {
  padding: $spacerm $desktop-gutter;

  @include tablet {
    padding-top: $spacerxxl;
    padding-bottom: $spacerxxl;
    padding-left: $desktop-small-gutter;
    padding-right: $desktop-small-gutter;
  }
  @include smartphone {
    padding-top: $spacerm;
    padding-left: $desktop-xsmall-gutter;
    padding-right: $desktop-xsmall-gutter;
  }
  &__list {
    list-style: none;
    margin: 0;
    @include hide-scrollbar;
    overflow-x: scroll;
    padding: 0;
    display: flex;
    padding-bottom: $spacers;
    &__item {
      min-width: 24%;
      margin-right: 32px;
      filter: none;
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      img {
        all: unset;
        min-width: 200px;
        min-height: 116px;
        display: block;
        margin-bottom: $spacerxs2;
      }
      &:hover {
        filter: none;
        -webkit-filter: grayscale(0%);
        -moz-filter: grayscale(0%);
        -ms-filter: grayscale(0%);
        -o-filter: grayscale(0%);
      }
      @include tablet {
        min-width: 45%;
        margin-right: 16px;
      }
      @include smartphone {
        min-width: 60%;
        margin-right: 16px;
      }
    }
  }
}
