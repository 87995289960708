// Palette

// Colour Variables
$white: #ffffff;
$black: #000000;
$black_figma: #0a0a14;
$shadow: rgba(0, 0, 0, 0.1);

$hover: #515151;

$text: #131223;
$text2: #6f6e75;

$primary: #2824e5;
$primary_hover: #3e3ae8;
$secondary: #0d0068;
$secondary_hover: #3e3391;
$accent: #d92792;
$accent_hover: #dd3c9d;

$grey1: #515151;
$grey2: #8c8a9f;
$grey3: #c9c9d3;
$grey4: #dddde7;
$grey5: #ebebf5;
$grey6: #f5f5ff;

$background1: #e7e6f0;
$background2: #cce0d6;
$background3: rgba(217, 39, 146, 0.1);
$background4: #f9f9fe;
$background5: #e1e0f0;
$background6: #e0eefb;
$background7: rgba(0, 101, 49, 0.1);
$background8: rgba(13, 0, 104, 0.1);
$background9: #8b8a9f;
$background10: #fafaff;
$background11: #fbe9f5;
$background12: #f9e8e8;
$background13: #f9eee6;
$background14: #120781;
$background15: #140a8b;
$background16: #d8d6e9;

$background_error: rgba(190, 84, 9, 0.2);

$state1: #006531;
$state2: #be5409;
$state3: #c61811;
$state4: #8c8a9f;
$state5: rgba(#0d0068, 0.4);
$state6: rgba(#d92792, 0.5);
$state7: rgba(#0d0068, 0.1);
$state8: #458e5e;
$state9: rgba(#006531, 0.2);
$state10: rgba(#006531, 0.65);
$state11: #3e3391;

$form_hover: #0d0067;

$modal_grey: rgba(19, 18, 35, 0.5);
$menu_background_grey: rgba(19, 18, 35, 0.8);

$static_page_info_color: #6f6e75;

$chip_selected: #bed8f7;
