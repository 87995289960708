.desktop-layout {
  min-height: 100vh;
  &__header-wrapper {
    position: relative;
    z-index: 2;
  }
  &__header-footer {
    position: relative;
    z-index: 2;
  }
  &__body {
    z-index: 1;
    min-height: calc(100vh - #{$footer-height+$main_header-height});
    background: $grey6 url('../../public/assets/img/modal_bg.svg') repeat 0 0;
    display: flow-root;
  }
}
