// desktop_faq

.desktop_faq {
  margin: 0 0 $spacerxxl4 0;
  // padding: $spacerxs2 0 $spacerxs2 $desktop-gutter;
  &__title {
    @extend .title_heading_m;
    margin: 0 0 $spacerxs2 0;
    padding: 0;
  }
  &__toggle {
    float: right;
    border: none;
    outline: none;
    background: url('../../public/assets/img/ico_arrow_up_grey.svg') no-repeat
      50% 50%;
    width: 32px;
    height: 32px;
    cursor: pointer;
    &--opened {
      background-image: url('../../public/assets/img/ico_arrow_down_grey.svg');
    }
    span {
      display: none;
    }
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    &__item {
      cursor: pointer;
      $self: &;
      background-color: $grey6;
      margin-bottom: $spacerxxs2;
      border-radius: 4px;
      &__question {
        padding: $spacerxs2;
        &__toggle {
          float: right;
          border: none;
          outline: none;
          background: url('../../public/assets/img/ico_plus_blu.svg') no-repeat
            50% 50%;
          width: 25px;
          height: 32px;
          cursor: pointer;
          span {
            display: none;
          }
        }
      }
      &__answer {
        @extend .text_xs;
        margin: 0;
        padding: $spacerxs2 $spacerxs2 0 $spacerxs2;
        background-color: $white;
        display: none;
        p,
        ul {
          margin: 0 0 $spacerxs2 0;
          padding: 0;
        }
      }
      &--opened {
        #{$self}__question {
          &__toggle {
            background-image: url('../../public/assets/img/ico_less_blu.svg');
          }
        }
        #{$self}__answer {
          display: block;
        }
      }
    }
  }
}
