// bid_stepper

.bid_stepper {
  &__loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    z-index: 10;

    .loading {
      margin-top: 100px;
      z-index: 30;
    }
  }

  &--fixed {
    height: 100vh;
    overflow: hidden;
  }

  .main_header {
    position: relative;
    width: 100%;
    z-index: 1;
  }

  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $modal_grey;
    z-index: 0;

    @include tablet {
      z-index: 10000;
    }

    .modal {
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    .modal {
      position: absolute;
      bottom: 0;
      width: 100%;

      .bid_done__price {
        margin-bottom: 60px;
      }
    }
  }
}
