// desktop_ordering_block

.desktop_ordering_block {
  padding: 16px;
  &__field {
    margin: 0 0 $spacers 0;
    padding: 0 0 $spacerxs2 0;
    border: none;
    border-bottom: 1px solid $grey4;
    &__title {
      @extend .title_heading_s;
      margin: 0 0 $spacerxxs2 0;
      padding: 0;
    }
  }
}
