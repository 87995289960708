// Tools

// Clearing
.clear_fix {
  &:before,
  &:after {
    content: '';
    display: block;
    overflow: hidden;
  }
  &:after {
    clear: both;
  }
}

// Alignment
.align_right {
  text-align: right;
}

.align_left {
  text-align: left;
}

.align_center {
  text-align: center;
}

.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}

// Positioning
.position_relative {
  position: relative;
}

.float_left {
  float: left;
}

.float_right {
  float: right;
}

// margin & padding
.no-padding {
  padding: 0px !important;
}
.no-margin {
  margin: 0px !important;
}
.margin-bottom-16 {
  margin-bottom: $spacerxs2 !important;
}

.margin-top-16 {
  margin-top: $spacerxs2 !important;
}

.padding-20 {
  padding: 20px;
}
.no-padding-top {
  padding-top: 0px !important;
}

//align flex
.align-items-center {
  display: flex;
  align-items: center;
  flex-direction: column;
}

// wrap
.white-space-pre-wrap {
  white-space: pre-wrap;
}

.layout-max-width {
  max-width: $layout-width;
  margin-left: auto;
  margin-right: auto;
}

// media-query
.no-xs-smartphone {
  @include xs-smartphone {
    display: none !important;
  }
}

.no-tablet {
  @include tablet {
    display: none !important;
  }
}
.no-desktop {
  @include no-desktop {
    display: none !important;
  }
}

// utility
.block_separator {
  display: block;
  margin: $spacerxs2;
  border: none;
  background-color: transparent;
  border-top: 1px solid $grey4;
}
