// maintenance

.maintenance {
  margin-bottom: 170px;
  padding: 100px $desktop-xsmall-gutter 200px $desktop-xsmall-gutter;
  background: transparent url('../../public/assets/img/ico_maintenance.svg') no-repeat 50% 250px;
  min-height: calc(100vh - 188px - 170px);
  @include tablet {
    min-height: calc(100vh - 268px - 170px);
    background: transparent url('../../public/assets/img/ico_maintenance.svg') no-repeat 50% 100%;
  }
  @include smartphone {
    padding-top: $spacerxxl;
    padding-bottom: 180px;
    margin-bottom: 40px;
    min-height: calc(100vh - 268px);
    background: transparent url('../../public/assets/img/ico_maintenance.svg') no-repeat 50% 300px;
  }
  @include s-smartphone {
    min-height: calc(100vh - 180px);
  }
  &__title {
    @extend %font-spacegrotesk-bold;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    margin: 0 0 $spacerxxs2 0;
    padding: 0;
    @include smartphone {
      font-size: 40px;
      line-height: 48px;
    }
  }
  &__sub_title {
    @extend .title_subheading_m;
    color: $text;
    text-align: center;
    margin: 0;
    padding: 0;
    @include smartphone {
      font-size: 20px;
      line-height: 36px;
    }
  }
}
