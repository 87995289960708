// costs

.costs {
  padding: 0 $spacerxs2;
  &__title {
    @extend .title_heading_m;
    margin: 0 0 $spacerxxs2 0;
    padding: 0;
  }
  &__text {
    color: $grey2;
    margin: 0;
    padding: 0 0 $spacerxxs2 0;
  }
  &__list {
    list-style: none;
    margin: 0 0 $spacerm 0;
    padding: 0;
    &__item {
      background-color: $grey6;
      border-radius: 4px;
      margin-bottom: $spacerxs2;
      padding: $spacerxs2;
      &:last-child {
        margin-bottom: 0;
      }
      &__data {
        @extend .title_subheading_xs;
        color: $grey2;
        margin: 0;
        padding: 0;
      }
      &__price {
        @extend .title_number_l;
        margin: 0;
        padding: 0;
      }
    }
  }
  &__costs_list {
    list-style: none;
    margin: 0;
    padding: 0;
    &__item {
      border: 1px solid $grey4;
      border-radius: 4px;
      background-color: $white;
      // margin-bottom: $spacerxs2;
      padding: $spacerxs $spacerxxs2;
      &:last-child {
        margin-bottom: 0;
      }
      &__value {
        @extend .title_heading_xxs;
        color: $grey2;
        margin: 4px 0 0 0;
        padding: 0;
        float: right;
        &__number {
          @extend .title_number_m;
          @include smartphone {
            font-size: 20px;
          }
        }
      }
    }
  }
}
