// summary2

.landing_visit {
  &__title {
    @extend .title_heading_xl;
    text-align: center;
    margin: $spacerxxl4 0 $spacerxxl3 0;
    padding: 0;
    @include smartphone {
      font-size: 32px;
      line-height: 40px;
      margin-top: $spacerxl;
      margin-bottom: $spacerxl;
    }
  }
  &__wrapper {
    max-width: 340px;
    margin: 0 auto 200px auto;
    @include smartphone {
      margin-bottom: $spacerxxl4;
    }
  }
}
