// building_pictures

.building_pictures {
  margin: 0;
  padding: 0;
  position: relative;
  > div {
    margin-bottom: $spacerxs2 !important; // fix for NextJs
  }
  &__picture {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 100%;
  }
  &__auction_created {
    @extend %font-inter-medium;
    position: absolute;
    top: $spacerxs2;
    // right: $spacerxs2; Why in 06/10/2021 value is not "0"?
    right: 0;
    color: $white;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    background-color: $state6;
    margin: 0;
    padding: 2px $spacerxs2 2px $spacerxxs2;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &__auction_coming_soon {
    @extend .building_pictures__auction_created;
    background-color: $state11;
  }
  &__auction_under_evaluation {
    @extend .building_pictures__auction_created;
    background-color: $state2;
  }
  &__auction_running {
    @extend .building_pictures__auction_created;
    background-color: $state5;
  }
  &__auction_closed {
    @extend .building_pictures__auction_created;
    background-color: $state3;
  }
  &__auction_lose {
    @extend .building_pictures__auction_created;
    background-color: $state3;
  }
  &__auction_win {
    @extend .building_pictures__auction_created;
    background-color: $state10;
  }
  &__auction_sold {
    @extend .building_pictures__auction_created;
    background-color: $state3;
  }
  &__only_by_now {
    @extend .building_pictures__auction_created;
    background-color: $state5;
  }
  &__back_button {
    @include button_ico(left, no, arrow_left_white);
    position: absolute;
    top: 8px;
    left: 12px;
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: none;
    outline: none;
  }
  &__pagination {
    @extend %font-inter-bold;
    position: absolute;
    bottom: 0;
    left: 16px;
    color: $white;
    background-color: $modal_grey;
    padding: 2px $spacerxxs2;
    border-radius: 4px;
    font-size: 12px;
    line-height: 20px;
  }
  &__slide_pagination {
    list-style: none;
    margin: 0;
    padding: 0;
    &__item {
      $self: &;
      position: absolute;
      top: calc(50% - 16px);
      &__button {
        border: none;
        outline: none;
        background-color: transparent;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        width: 32px;
        height: 32px;
        cursor: pointer;
        span {
          display: none;
        }
      }
      &--prev {
        left: 0;
        #{$self}__button {
          background-image: url('../../public/assets/img/ico_pagination_prev.svg');
        }
      }
      &--next {
        right: 0;
        #{$self}__button {
          background-image: url('../../public/assets/img/ico_pagination_next.svg');
        }
      }
    }
  }
  &__slide_control {
    // is __slide_pagination second version
    list-style: none;
    margin: 0;
    padding: 0;
    &__arrow {
      position: absolute;
      top: calc(50% - #{$spacerxl});
      button {
        outline: none;
        border: none;
        margin: 0;
        padding: 0;
        display: block;
        cursor: pointer;
        width: $spacerxl;
        height: $spacerxl;
        background-color: $white;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        border-radius: 4px;
        span {
          display: none;
        }
      }
      &--prev {
        left: $spacerxs2;
        button {
          background-image: url('../../public/assets/img/carousel_arrow_left.svg');
        }
      }
      &--next {
        right: $spacerxs2;
        button {
          background-image: url('../../public/assets/img/carousel_arrow_right.svg');
        }
      }
    }
  }
  &__social_actions {
    position: absolute;
    bottom: -20px;
    right: 16px;
    margin: 0;
    padding: 0;
    list-style: none;
    &__item {
      margin: 0 0 0 8px;
      padding: 0;
      display: inline-block;
      &__button {
        @extend .button_primary;
        @extend .button_primary--type2;
        &--save {
          @include button_ico(left, no, hearth_blu);
        }
        &--save_active {
          @include button_ico(left, no, hearth_blu_filled);
        }
        &--share {
          @include button_ico(left, no, share_blu);
        }
      }
    }
  }
  &__image_slider {
    &__image_container {
      height: 272px;
      background-color: $black;
      position: relative;
    }
    &__pagination {
      @extend %font-inter-bold;
      position: absolute;
      bottom: 0;
      left: 16px;
      color: $white;
      background-color: $modal_grey;
      padding: 2px $spacerxxs2;
      border-radius: 4px;
      font-size: 12px;
      line-height: 20px;
    }
    &__back_button {
      @include button_ico(left, no, arrow_left_white);
      position: absolute;
      top: 8px;
      left: 12px;
      width: 24px;
      height: 24px;
      background-color: transparent;
      border: none;
      outline: none;
    }
  }
}
