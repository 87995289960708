// desktop_visit_booking

.desktop_visit_booking {
  @include box-shadow(z2);
  background-color: $white;
  margin: 0 $spacerxs2 $spacers $spacerxs2;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid $grey5;
  border-bottom: 0;
  @include smartphone {
    margin-bottom: 0;
  }
  &__title {
    @extend .title_subheading_xxs;
    height: 28px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: $grey5;
    margin-top: 0;
    padding: 4px 15px 0 17px;
  }
  &__content {
    padding: $spacerxs2;
    &__cta {
      @extend .button_primary;
      @extend .button_primary--type3;
      width: 100%;
      margin-top: $spacers;
    }
  }
}
