// Typography

// font-face
@font-face {
  font-family: 'SpaceGrotesk-Bold';
  src: url('../public/assets/font/SpaceGrotesk-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../public/assets/font/SpaceGrotesk-Bold.ttf') format('truetype'),
    url('../public/assets/font/SpaceGrotesk-Bold.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'SpaceGrotesk-Medium';
  src: url('../public/assets/font/SpaceGrotesk-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../public/assets/font/SpaceGrotesk-Medium.ttf') format('truetype'),
    url('../public/assets/font/SpaceGrotesk-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Bold';
  src: url('../public/assets/font/Inter-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../public/assets/font/Inter-Bold.ttf') format('truetype'),
    url('../public/assets/font/Inter-Bold.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Inter-Medium';
  src: url('../public/assets/font/Inter-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../public/assets/font/Inter-Medium.ttf') format('truetype'),
    url('../public/assets/font/Inter-Medium.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Inter-Regular';
  src: url('../public/assets/font/Inter-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../public/assets/font/Inter-Regular.ttf') format('truetype'),
    url('../public/assets/font/Inter-Regular.woff') format('woff');
  font-display: swap;
}

$font-spacegrotesk-bold: 'SpaceGrotesk-Bold', Arial, sans-serif;
$font-spacegrotesk-medium: 'SpaceGrotesk-Medium', Arial, sans-serif;
$font-inter-bold: 'Inter-Bold', Helvetica, Arial, sans-serif;
$font-inter-medium: 'Inter-Medium', Helvetica, Arial, sans-serif;
$font-inter-regular: 'Inter-Regular', Helvetica, Arial, sans-serif;

%font-spacegrotesk-bold {
  font-family: $font-spacegrotesk-bold;
  font-weight: normal;
}
%font-spacegrotesk-medium {
  font-family: $font-spacegrotesk-medium;
  font-weight: normal;
}
%font-inter-bold {
  font-family: $font-inter-bold;
  font-weight: normal;
}
%font-inter-medium {
  font-family: $font-inter-medium;
  font-weight: normal;
}
%font-inter-regular {
  font-family: $font-inter-regular;
  font-weight: normal;
}
