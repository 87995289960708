// loading

.loading {
  background-color: $white;
  padding: $spacerm;

  &_type1 {
    padding: 0;

    .loading__standard {
      background-size: contain;
    }
  }

  &__title {
    @extend .title_display_l;
    margin: $spacerl 0 0 0;
    text-align: center;
  }
  &__standard {
    background: transparent url('../../public/assets/img/loading.svg') no-repeat 50% 50%;
    width: 110px;
    height: 110px;
    margin: 0 auto;
    span {
      display: none;
    }
  }
  &__lock {
    background: transparent url('../../public/assets/img/loading.svg') no-repeat 50% 50%;
    &__icon {
      background: transparent url('../../public/assets/img/ico_lock.svg') no-repeat 50% 50%;
      width: 110px;
      height: 110px;
      margin: 0 auto;
    }
    span {
      display: none;
    }
  }
  &--type2 {
    background: $grey6 url('../../public/assets/img/modal_bg.svg') repeat 0 0;
  }
}
