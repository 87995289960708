// user_menu

$user_menu_footer-height: 145px;
$user_menu_header-height: 80px;

.user_menu {
  background-color: $white;
  width: 300px;
  position: relative;
  padding-bottom: $user_menu_footer-height;
  height: calc(100% - 84px);

  @include tablet {
    height: calc(100% - 50px);
  }
  @include smartphone {
    height: calc(100% - 50px);
  }

  &__header {
    background: $grey6 url('../../public/assets/img/modal_bg.svg') repeat 0 0;
    height: $spacerxxl4;
    margin: 0;
    padding: 20px 24px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__welcome {
      @extend .title_display_m;
      background-color: transparent;
      background-position: 0 50%;
      background-repeat: no-repeat;
      background-image: url('../../public/assets/img/menu_default.svg');
      margin: 0;
      padding: 0 0 0 30px;
      &--logged {
        background-image: url('../../public/assets/img/menu_logged.svg');
      }
    }
    &__submenu {
      @extend .title_heading_xxs;
      text-transform: uppercase;
      background-color: transparent;
      background-position: 0 50%;
      background-repeat: no-repeat;
      background-image: url('../../public/assets/img/ico_menu_back2.svg');
      margin: 0;
      padding: $spacerxxs2 0 $spacerxxs2 30px;
    }
  }
  &__header_arrow {
    width: 40px;
    height: 40px;
    display: inline-block;
    background-image: url('../../public/assets/img/menu_arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
  }
  &__article {
    @include hide-scrollbar;
    position: absolute;
    top: $spacerxxl4;
    bottom: $user_menu_footer-height;
    width: 100%;
    overflow-y: auto;
    padding: $spacerxs2 $spacerxs2 $spacerxs2 $spacerxs2;
    &__nav {
      margin: 0 0 $spacerxs2 0;
      padding: 0;
      &__list {
        margin: 0;
        padding: 0 0 $spacerxxs2 0;
        list-style: none;
        border-bottom: 1px solid $grey4;
        &--no_border {
          border-bottom: none;
        }
        &__item {
          @extend .title_subheading_xxs;
          margin: 0;
          padding: $spacerxs2 0 $spacerxs2 0;
          text-transform: uppercase;
          background: transparent url('../../public/assets/img/menu_link.svg') no-repeat center
            right;

          &--type2 {
            background: none;

            &.user_menu__article__nav__list__item--active {
              background: transparent url('../../public/assets/img/ico_menu_selected.svg') no-repeat
                center right !important;
            }
          }
        }
      }
    }
    &__option_list {
      margin: 0 0 $spacerxs2 0;
      padding: 0 0 $spacerxxs2 0;
      list-style: none;
      border-bottom: 1px solid $grey4;
      &__item {
        @extend .text_xs;
        background-color: transparent;
        background-position: 0 50%;
        background-repeat: no-repeat;
        padding: $spacerxs 0 $spacerxs 30px;
        &--building {
          background-image: url('../../public/assets/img/menu_hearth.svg');
        }
        &--search {
          background-image: url('../../public/assets/img/menu_search.svg');
        }
      }
    }
    &__action_button {
      @extend .button_tertiary;
      @extend .button_tertiary--small;
      font-family: $font-inter-bold;
    }
  }
  &__footer {
    height: $user_menu_footer-height;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $grey6 url('../../public/assets/img/modal_bg.svg') repeat 0 0;
    margin: 0;
    padding: 24px;
    &__list {
      list-style: none;
      margin: 0;
      padding: 0;
      &__item {
        @extend .text_xs;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: 0 50%;
        margin-bottom: $spacerxs;
        padding-left: 25px;
        &:last-child {
          margin-bottom: 0;
        }
        &--hour {
          background-image: url('../../public/assets/img/menu_footer_hour.svg');
        }
        &--tel {
          background-image: url('../../public/assets/img/menu_footer_phone.svg');
        }
        &--email {
          background-image: url('../../public/assets/img/menu_footer_mail.svg');
        }
      }
    }
  }
}
