// calendar

.calendar {
  position: relative;
  margin-top: $spacerxs2;
  overflow: hidden;
  z-index: 101;
  @include tablet {
    z-index: unset;
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    &__item {
      flex: 0 0 auto;
      margin-right: $spacerxxs;
      padding: $spacerxs $spacerxxs2;
      border: 1px solid $grey5;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      &--selected {
        border: 1px solid $primary;
      }
      &--disabled {
        cursor: not-allowed;
        opacity: 0.3;
      }
      &--padding_reduced {
        padding: $spacerxxs2 $spacerxxs;
      }
      &__day,
      &__month {
        @extend .title_heading_xxxs;
        display: block;
        color: $grey2;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__day_number {
        @extend .title_number_l;
      }
      &__hour {
        @extend .title_number_s;
      }
      &__hour2 {
        @extend .title_heading_xxxs;
        margin-left: $spacerxxs;
        color: $grey2;
        text-transform: uppercase;
      }
    }
  }
  &__controller {
    list-style: none;
    margin: 0;
    padding: 0;
    &__item {
      position: absolute;
      top: calc(50% - 12px);
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid $secondary;
      background-color: $white;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      cursor: pointer;
      &--prev {
        left: 10px;
        background-image: url('../../public/assets/img/pagination_arrow_prev.svg');
      }
      &--next {
        right: 10px;
        background-image: url('../../public/assets/img/pagination_arrow_next.svg');
      }
      span {
        display: none;
      }
    }
  }
}
