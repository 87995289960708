.privacy_general_condition {
  padding: $spacerm $desktop-gutter;
  max-width: 960px;

  @include tablet {
    padding-left: $desktop-small-gutter;
    padding-right: $desktop-small-gutter;
  }
  @include smartphone {
    padding-left: $desktop-xsmall-gutter;
    padding-right: $desktop-xsmall-gutter;
  }

  &__title {
    @extend .title_display_xl;
    margin-bottom: $spacerl;
  }

  &__divider_small {
    border: 1px solid $grey2;
    height: 2px;
    width: $spacerxxl3;
    margin-bottom: $spacerm;
  }

  &__divider {
    border: 1px solid $grey4;
    height: 1px;
    max-width: 937px;
    margin: 30px 0;
  }

  &__text {
    @extend .text_s;
    color: $text;
    font-size: 20px;
    line-height: 32px;
    margin: 32px 0 0 0;
  }
}
