// process_feedback

.process_feedback {
  height: 100%;
  background: $white url('../../public/assets/img/bg_pattern2.svg') repeat 0 0;
  &--no_background {
    background: $white;
  }
  &--type1 {
    background: $white url('../../public/assets/img/bg_pattern2.svg') repeat 0 0;
  }
  &--type2 {
    background: none;
    .process_feedback__message_block {
      background: none;
    }
  }
  &__message_block {
    padding: $spacerxxl4 0 $spacerl 0;
    background: transparent url('../../public/assets/img/bg_pattern2.svg') repeat 0 0;
    &__title {
      @extend .title_display_l;
      background: transparent url('../../public/assets/img/feedback_ok.svg') no-repeat 50% 0;
      margin: 0 0 $spacerm 0;
      padding: 120px 0 0 0;
      text-align: center;
    }
    &__message {
      @extend .title_subheading_xs;
      text-align: center;
      padding: 0 $spacerm 0 $spacerm;
      color: $text;
      &__emphasy {
        color: $grey1;
        display: block;
        font-style: normal;
        font-weight: normal;
      }
    }
  }
  &__form_block {
    background-color: $white;
    padding: $spacerm 0 0 0;
    &__title {
      @extend .title_heading_s;
      margin: 0 0 $spacerxs2 0;
      padding: 0 $spacerm;
    }
    .login__form {
      box-shadow: none;
      border-radius: 0;
      padding-left: $spacerxs2;
      padding-right: $spacerxs2;
    }
    .login__form__field__row__button {
      @extend .button_primary--type3;
    }
  }
}
