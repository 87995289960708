// spinner

.spinner {
  display: flex;
  align-content: center;

  &__style1 {
    .spinner__input_value {
      @extend .input_style1;
      flex: 1;
      min-width: unset;
      max-width: unset;
      height: 40px;
      margin: 0 $spacerxxs2;
    }
  }

  &__style2 {
    .spinner__input_value {
      @extend .input_style1;
      flex: 1;
      min-width: unset;
      max-width: unset;
      height: 48px;
    }
  }

  &__style3 {
    .spinner__input_value {
      @extend .input_style1;
      flex: 1;
      min-width: unset;
      max-width: unset;
      height: 48px;
      text-align: left;
    }
  }

  &__value {
    flex: 0 0 auto;
    @extend .title_heading_xs;
    display: block;
    min-width: 100px;
    height: 40px;
    text-align: center;
    padding: 8px 5px 0 5px;
  }
  &__input_value {
    flex: 0 0 auto;
    @extend .title_heading_xs;
    display: block;
    min-width: 100px;
    max-width: 110px;
    height: 40px;
    text-align: center;
    padding: 8px 5px 0 5px;
    background-color: transparent;
    border: none;
    outline: none;
  }
  &__button {
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: none;
    outline: none;
    background-color: $primary;
    &--minus {
      background-image: url('../../public/assets/img/spinner_ico_minus.svg');
    }
    &--plus {
      background-image: url('../../public/assets/img/spinner_ico_plus.svg');
    }
    span {
      display: none;
    }
    &:disabled {
      background-color: $grey6;
      background-image: url('../../public/assets/img/spinner_ico_minus.svg');
    }
  }
}
