// Layout

.page_wrapper {
  height: 100%;
}

.main_page {
  position: relative;
  height: 100%;
  max-width: $layout-width;
  margin-left: auto;
  margin-right: auto;

  &--height_auto {
    height: auto;
  }
}

.pre_footer {
  &__info {
    padding: $spacerxs2;
    background: $background10 url('../public/assets/img/bg_pattern.svg') repeat
      0 0;
    &__list {
      @include tablet {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-left: $spacerxs;
        &__item {
          margin-bottom: $spacerxs !important;
        }
      }
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center;
      &__item {
        @extend .text_xs;
        display: inline-block;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: 0 50%;
        margin: 0 $spacerl 0 0;
        padding-left: 25px;
        &:last-child {
          margin-bottom: 0;
        }
        &--hour {
          background-image: url('../public/assets/img/menu_footer_hour.svg');
        }
        &--tel {
          background-image: url('../public/assets/img/menu_footer_phone.svg');
        }
        &--email {
          background-image: url('../public/assets/img/menu_footer_mail.svg');
        }
      }
    }
  }
  &__menu {
    background-color: $grey2;
    padding: $spacerxxl2 $desktop-gutter $spacerxxl2 $desktop-gutter;
    display: flex;
    @include tablet {
      padding: $spacers $desktop-xsmall-gutter $spacers $desktop-xsmall-gutter;
      flex-wrap: wrap;
    }
    &__block {
      flex: 1 1 auto;
      @include tablet {
        flex: 0 0 100%;
        text-align: left;
      }
      &--newsletter {
        flex: 31%;
      }
      &__title {
        @extend .title_subheading_s;
        color: $white;
        margin: 0 0 $spacerxs2 0;
        padding: 0;
      }
      &__list {
        @extend .title_subheading_xxs;
        list-style: none;
        margin: 0;
        padding: 0;

        &__item {
          margin-bottom: $spacerxs2;
          a:link,
          a:visited {
            color: $grey4;
          }
          &--hide_at_five {
            &:nth-child(n + 6) {
              position: absolute;
              top: -9999px;
              left: -9999px;
            }
          }
        }
      }
      &__button,
      &__field {
        width: 320px;
        margin-bottom: $spacerxs2;
        @include m-desktop {
          width: 200px;
        }
        @include tablet {
          margin-left: 0;
          margin-right: 0;
          width: 100%;
        }
      }
      &__button {
        @extend .button_primary;
        display: block;
        text-align: center;
        span {
          background: transparent
            url('../public/assets/img/ico_footer_mail.svg') no-repeat 0 50%;
          padding-left: 30px;
        }
      }
    }
  }
}

.layout_aside {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  margin: 0;
  &__content {
    flex: 1 1 auto;
    margin-right: $spacers;
    &--type-gallery {
      margin-right: 8px;
      max-width: 1060px;
      position: relative;
    }
  }
  &__aside {
    flex: 0 0 $layout_aside-width;
    position: relative;
    &--no_sticky {
      margin-top: 0;
    }
    &__dx {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
    }
  }
}

@media screen and (max-height: 930px) {
  .layout_aside__aside__dx {
    position: -webkit-static;
    position: static;
  }
}

.layout_row {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  @include tablet {
    flex-flow: row wrap;
  }
  &__col {
    flex: 1 1 auto;
    &--one {
      width: 100%;
    }
    &--two {
      width: 50%;
      @include tablet {
        width: 100%;
      }
    }
    &--three {
      width: 33%;
    }
    &--three_of_two {
      width: 66%;
    }
  }
}

.layout_aside_menu {
  width: 100%;
  display: grid;
  grid-template-columns: $layout_menu-width 1fr;
  grid-gap: $spacerxl;
  grid-template-areas: 'menu content';
  justify-items: stretch;
  &__menu {
    grid-area: menu;
    @include box-shadow(z5);
  }
  &__content {
    grid-area: content;
  }
}

.layout_extended_min_height {
  min-height: $layout-min-height;
}
