// Layout

.page_wrapper {
  height: 100%;
}

.main_page {
  position: relative;
  height: 100%;
}

.main_footer {
  background-color: $white;
  padding-top: $spacerxs2;
  &__nav {
    $self: &;
    margin: 0;
    padding: 0 $spacerxs2;
    background-color: $background9;
    &__title {
      @extend .title_subheading_xs;
      color: $white;
      border-bottom: 1px solid $grey3;
      margin: 0;
      padding: $spacers 0;
      background-color: transparent;
      background-position: center right;
      background-repeat: no-repeat;
      background-image: url('../public/assets/img/footer_accordion_bottom.svg');
      &--no_border {
        border-bottom: none;
      }
    }
    &__list {
      display: none;
      margin: $spacerxs 0 0 0;
      padding: 0 0 $spacerxs 0;
      list-style: none;
      border-bottom: 1px solid $grey3;
      &__item {
        @extend .title_subheading_xxs;
        color: $grey4;
        margin: 0;
        padding: $spacerxxs2 0;
      }
    }
    &--opened {
      #{$self}__title {
        @extend .title_subheading_s;
        background-image: url('../public/assets/img/footer_accordion_top.svg');
        color: $white;
        padding-bottom: $spacerxs2;
      }
      #{$self}__list {
        display: block;
      }
    }
  }
  &__newsletter {
    margin: $spacerxs2 $spacerxs2 $spacers $spacerxs2;
    padding-bottom: $spacers;
    border-bottom: 1px solid $grey4;
    label {
      @extend .title_subheading_s;
      color: $text;
      display: block;
      margin: 0 0 $spacerxs 0;
      padding: 0;
    }
    input {
      width: 100%;
      margin-bottom: $spacerxs2;
    }
    &__button {
      width: 100%;
      @extend .button_primary;
      text-align: center;
      span {
        background: transparent url('../public/assets/img/ico_footer_mail.svg')
          no-repeat 0 50%;
        padding-left: 30px;
      }
    }
  }
  &__info {
    margin: $spacerxs2;
    padding: 0;
    font-size: 10px;
    line-height: 13px;
    img {
      vertical-align: middle;
      margin-right: $spacerxs;
    }
  }
  &__address {
    @extend .text_xxs;
    margin: $spacerxs2;
    padding: 0 0 $spacerxs2 0;
    font-style: normal;
    color: $black;
  }
}

.layout_row {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  @include tablet {
    flex-flow: row wrap;
  }
  &__col {
    flex: 1 1 auto;
    &--one {
      width: 100%;
    }
    &--two {
      width: 50%;
      @include tablet {
        width: 100%;
      }
    }
    &--three {
      width: 33%;
    }
    &--three_of_two {
      width: 66%;
    }
  }
}

.layout_table_responsive {
  max-width: 100%;
  overflow-x: auto;
}

.map_container {
  height: calc(100vh - #{$sub_header_results-height} - #{$main_header-height});
}
