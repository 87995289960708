// desktop_reason_why

.desktop_reason_why {
  padding: $spacerxxl3 $desktop-gutter;
  @include m-desktop {
    padding-left: $desktop-small-gutter;
    padding-right: $desktop-small-gutter;
  }
  @include tablet {
    padding: $spacerm $desktop-xsmall-gutter;
  }
  &__title {
    @extend .title_heading_l;
    margin: 0 0 $spacerl 0;
    padding: 0;
    @include smartphone {
      font-size: 24px;
    }
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    @include m-desktop {
      flex-wrap: wrap;
    }
    @include tablet {
      flex-basis: 50%;
      justify-content: center;
    }
    @include smartphone {
      text-align: center;
      margin-bottom: 15px;
      justify-content: center;
    }
    &--collaborate {
      margin-top: 40px;
      justify-content: space-between;
      flex-flow: row wrap;
      .desktop_reason_why__list__item {
        width: calc(50% - $spacerl);
        margin-bottom: $spacers;
        margin-right: 0;

        @include tablet {
          width: 100%;
          margin: 0px 0px 15px 0px;
          &:nth-child(2n) {
            margin-left: 0px;
          }
        }
        @include smartphone {
          width: 100%;
        }
      }
    }
    &__item {
      @extend .title_display_m;
      flex: 0 0 auto;
      border: 1px solid $grey6;
      border-radius: 4px;
      padding: 100px $spacerxs2 $spacers $spacerm;
      width: 270px;
      margin: 0 $spacerl $spacerxxs 0;
      background-color: $white;
      background-position: $spacerxs2 $spacerxs2;
      background-repeat: no-repeat;

      &__breakline {
        flex-basis: 100%;
        height: 0;
      }

      @include tablet {
        &:nth-child(2n) {
          margin-left: 36px;
        }
        margin: 0px 0px 16px 0px;
      }
      @include smartphone {
        &:nth-child(2n) {
          margin-left: 0px;
        }
        margin: 0px 0px 15px 0px;
        background-position: 50% $spacerxs2;
        padding-left: $spacerxxs2;
        padding-right: $spacerxxs2;
        font-size: 20px;
      }
      &--fast {
        background-image: url('../../public/assets/img/reason_why_fast.svg');
      }
      &--trasparent {
        background-image: url('../../public/assets/img/reason_why_trasparent.svg');
      }
      &--secure {
        background-image: url('../../public/assets/img/reason_why_secure.svg');
      }
      &--professional {
        background-image: url('../../public/assets/img/reason_why_professional.svg');
      }
      &--idea {
        background-image: url('../../public/assets/img/reason_why_idea.svg');
      }
      &:last-child {
        margin-right: 0;
      }
      span {
        @extend .text_xs;
        display: block;
        @include smartphone {
          line-height: 20px;
        }
      }
    }
  }
}
