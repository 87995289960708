// desktop_tab

.desktop_tab_list_wrapper {
  margin: $main_tab_container-margin-top $desktop-gutter 0 $desktop-gutter;
  border-bottom: 2px solid $grey4;
}

.desktop_tab_list {
  @include hide-scrollbar;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  overflow-x: auto;
  position: relative;
  top: 2px;
  z-index: 100;
  &__item {
    @extend %font-inter-bold;
    flex: 0 0 auto;
    margin: 0 40px 0 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    border: 0px;
    color: $grey2;
    min-width: 130px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &--active {
      color: $primary;
      border-bottom: 2px solid $primary;
    }
  }
}
