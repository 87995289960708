// static_template

.static_template {
  padding: $spacerm $desktop-gutter;
  @include m-desktop {
    padding-left: $desktop-small-gutter;
    padding-right: $desktop-small-gutter;
  }
  @include tablet {
    padding-top: 0;
    padding-left: $desktop-xsmall-gutter;
    padding-right: $desktop-xsmall-gutter;
  }
  &__divider_small {
    border: 1px solid $grey2;
    height: 2px;
    width: $spacerxxl3;
    margin-top: $spacerm;
    margin-bottom: $spacerm;
  }
  &__title {
    @extend .title_display_xxl;
    margin: $spacerm 0 $spacers 0;
    padding: 0;
    text-align: center;
    @include smartphone {
      font-size: 32px;
      line-height: 40px;
      margin-top: $spacerm;
    }
    &__left {
      @extend .title_display_xxl;
      margin: $spacerm 0 $spacers 0;
      padding: 0;
      text-align: left;
    }
  }
  &__subtitle {
    @extend .title_subheading_m;
    color: $black_figma !important;
    font-weight: bold;
    text-align: center;
    @include smartphone {
      font-size: 20px;
      line-height: 32px;
    }
    &__left {
      @extend .title_subheading_m;
      font-weight: bold;
      text-align: left;
    }
    &--padding-bottom {
      padding-bottom: 40px;
    }
  }
  &__first_image {
    padding: $spacerxxl2;
    overflow: hidden;
    @include tablet {
      padding: $spacerm 0;
    }
    &__list {
      display: flex;
      justify-content: space-between;
      list-style: none;
      margin: 0;
      padding: 0;
      @include smartphone {
        flex-direction: column;
      }
      &__item {
        $self: &;
        flex: 0 0 180px;
        position: relative;
        @include smartphone {
          flex: 0 0 33%;
          display: flex;
          align-items: center;
        }
        &__title {
          @extend .title_heading_l;
          color: $primary;
          position: absolute;
          top: 22px;
          left: 80px;
          @include smartphone {
            left: 65px;
            font-size: 24px;
          }
        }
        &__description {
          @extend .title_heading_m;
          color: $secondary;

          @include smartphone {
            font-size: 14px;
            line-height: 22px;
            max-width: calc(100% - 110px);
            text-align: left;
          }
        }
        &::before {
          content: '';
          display: block;
          height: 88px;
          width: 88px;
          margin-left: $spacers;
          margin-bottom: $spacerxxs2;
          background-color: $white;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-image: url('../../public/assets/img/stepper_step_big.svg');
          border-radius: 50%;
          @include smartphone {
            background-size: 60px;
            background-position: 0 50%;
            background-color: unset;
          }
        }
        &:after {
          content: '';
          display: block;
          border-bottom: 2px solid rgba($primary, 0.1);
          height: 1px;
          width: 58vw;
          position: absolute;
          top: 42px;
          left: 112px;

          @include smartphone {
            width: 36px;
            top: 90px;
            left: 36px;
            transform: rotateZ(90deg);
          }
        }

        &:nth-child(2),
        &:nth-child(3) {
          &:after {
            display: none;
          }
        }
        &:nth-child(2) {
          &:after {
            @include smartphone {
              display: block;
            }
          }
        }
      }
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__collaborate_images {
    list-style: none;
    display: grid;
    grid-auto-flow: column;
    scrollbar-width: none;
    @include tablet {
      overflow-x: scroll;
      scrollbar-width: none;
    }
    &__item {
      @include tablet {
        width: 33vw;
      }
      @include smartphone {
        width: 90vw;
      }
    }
    margin-top: 16px;
    margin-bottom: 40px;
  }

  &__image {
    //position: relative;
    //height: 560px;
    img {
      max-width: 100%;
      display: block;
    }
  }
  &__info {
    display: flex;
    @include tablet {
      flex-wrap: wrap;
    }
    &__container {
      flex: 1 1 auto;
      padding: $spacerxxl4 $spacerxxl $spacerxxl $spacerxxl;
      @include tablet {
        flex: 0 0 100%;
        padding: $spacerxxl2 $desktop-xsmall-gutter;
      }
      &__title {
        @extend .title_heading_s;
        color: $static_page_info_color;
        letter-spacing: 0.004em;
        margin: 0 0 $spacerxxs2 0;
        padding: 0;
      }

      &__text {
        font-size: 20px;
        line-height: 34px;
        margin: 0 0 $spacers 0;
        letter-spacing: 0.004em;

        &__padding {
          padding: $spacerm 0 $spacerxs2 0;
          margin: 0px;
        }
      }
      &__list {
        list-style: none;
        margin: 0 0 $spacerm 0;
        padding: 0;
        &__item {
          font-size: 20px;
          line-height: 32px;
          background: transparent
            url('../../public/assets/img/info_bullet_point.svg') no-repeat 0 5px;
          color: $text;
          margin-bottom: $spacerxs2;
          letter-spacing: 0.004em;
          &__text {
            padding-left: 50px;
          }
          &--credit-card {
            background-image: url('../../public/assets/img/bullet_credit_card.svg');
            background-size: 78px 62px;
            padding-left: $spacerxl;
            padding-top: $spacers;
          }
          &--clock {
            background-image: url('../../public/assets/img/bullet_clock.svg');
            background-size: 78px 62px;
            padding-left: $spacerxl;
            padding-top: $spacers;
          }
          &--eye {
            background-image: url('../../public/assets/img/bullet_eye.svg');
            background-size: 78px 62px;
            padding-left: $spacerxl;
            padding-top: $spacers;
          }
          &--lock {
            background-image: url('../../public/assets/img/bullet_lock.svg');
            background-size: 78px 62px;
            padding-left: $spacerxl;
            padding-top: $spacers;
          }
          &--professional {
            background-image: url('../../public/assets/img/bullet_professional.svg');
            background-size: 78px 62px;
            padding-left: $spacerxl;
            padding-top: $spacers;
          }
        }
      }
    }
    &__container_left {
      flex: 0 0 40%;
      background: $background4 url('../../public/assets/img/bg_pattern.svg')
        repeat 0 0;
      margin: 0;
      padding: $spacerxxl2 $spacerxxl $desktop-gutter $desktop-gutter;
      @include m-desktop {
        padding-left: $desktop-small-gutter;
      }
      @include tablet {
        flex: 0 0 100%;
        padding: $spacerxxl2 $desktop-xsmall-gutter;
      }
      &__title {
        @extend .title_heading_l;
        margin: 0 0 0 0;
        &--with_divider {
          &:before {
            content: '';
            display: block;
            border-top: 2px solid $grey2;
            height: 1px;
            width: $spacerxxl3;
            margin-bottom: $spacers;
          }
        }
      }
      &__sub_title {
        @extend .title_subheading_m;
        margin: $spacers 0 0 0;
        padding: 0;
        color: $text;
      }
    }
  }
  &__contacts {
    background: $background4;
    padding-top: $spacerxl;
    padding-bottom: $spacerxl;
    @include tablet {
      padding-top: $spacerm;
      padding-bottom: $spacerm;
    }
    &__title {
      @extend .title_display_xxl;
      padding: $spacerm 0 $spacers 120px;
      @include tablet {
        padding-left: 0 !important;
        font-size: 32px;
        line-height: 40px;
        text-align: left;
        margin-top: 0px;
        margin: 0 $spacerm;
        padding: 0px;
        margin-bottom: 0px;
        padding-bottom: $spacerxs2;
      }
      @include smartphone {
        padding-left: 0 !important;
      }
    }
    &__subtitle {
      padding-left: 90px;
      display: flex;
      @include tablet {
        padding-left: 0 !important;
        flex-wrap: wrap;
        text-align: left;
        justify-content: flex-start;
        &__container {
          text-align: left;
          align-items: flex-start;
          &__footer {
            padding-top: $spacerxs2;
          }
        }
      }
      &__container {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include tablet {
          margin: 0 $spacerm;
        }
        margin: $spacerm;

        &__item {
          @extend .text_xs;
          display: inline-block;
          background-color: transparent;
          background-repeat: no-repeat;
          background-position: 0 50%;
          margin-top: $spacerxs;
          &__companyname {
            @extend .text_xs;
            margin-top: 12px;
            font-family: $font-inter-bold;
            list-style-type: none;
          }
          &--hour {
            background-image: url('../../public/assets/img/menu_footer_hour.svg');
            padding-left: 22px;
          }
          &--tel {
            background-image: url('../../public/assets/img/menu_footer_phone.svg');
            padding-left: 22px;
          }
          &--email {
            background-image: url('../../public/assets/img/menu_footer_mail.svg');
            padding-left: 22px;
          }

          a:link,
          a:visited {
            text-decoration: none;
          }
        }
      }
    }
  }
}
