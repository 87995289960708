// summary

.summary {
  background-color: $white;
  padding: $spacerxs2;
  &--background_pattern {
    background: $grey6;
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    &__item {
      margin-right: $spacers;
      @include smartphone {
        font-size: 14px;
        margin-right: 10px;
      }
      &__title {
        @extend .title_subheading_xs;
        color: $grey2;
        margin: 0;
        padding: 0;
      }
      &__description {
        @extend .title_number_s;
        margin: 0;
        padding: 0;
      }
    }
    &--full_width {
      flex-wrap: wrap;
      margin-bottom: $spacers;
    }
  }
}

.summary_list {
  list-style: none;
  margin: 0;
  padding: 0 $spacerm;
  background-color: $white;
  &__item {
    @extend .text_s;
    margin-bottom: $spacerxs2;
    padding-left: 25px;
    background-position: 0 50%;
    background-repeat: no-repeat;
    background-color: transparent;
    &--visit {
      background-image: url('../../public/assets/img/summary_visit.svg');
    }
    &--address {
      background-image: url('../../public/assets/img/summary_address.svg');
    }
    &--link {
      background-image: url('../../public/assets/img/summary_link.svg');
    }
    &--date {
      background-image: url('../../public/assets/img/summary_date.svg');
    }
    &--name {
      background-image: url('../../public/assets/img/summary_name.svg');
    }
    &--email {
      background-image: url('../../public/assets/img/summary_email.svg');
      word-break: break-all;
    }
    &--tel {
      background-image: url('../../public/assets/img/summary_tel.svg');
    }
    &--virtual {
      background-image: url('../../public/assets/img/summary_virtual.svg');
    }
  }
}
