$desktop_user_menu_padding_left: 120px;

.desktop_user_menu {
  &__header {
    background-color: $grey6;
    height: 184px;
    padding-top: 95px;
    padding-left: $desktop_user_menu_padding_left;
  }

  &__items_wrapper {
    padding-left: $desktop_user_menu_padding_left;
    padding-bottom: $spacerm;
  }

  &__header_title {
    @extend .title_display_m;
    margin: 0;
  }
  &__item_icon {
    margin-right: 10px;
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    &--user {
      background-image: url('../../public/assets/img/ico_user_gray_outline.svg');
    }
    &--user_active {
      background-image: url('../../public/assets/img/ico_user_primary.svg');
    }

    &--auction {
      background-image: url('../../public/assets/img/ico_hammer_gray_outline.svg');
    }
    &--auction_active {
      background-image: url('../../public/assets/img/ico_hammer_primary.svg');
    }

    &--favorites {
      background-image: url('../../public/assets/img/ico_favorite_outline.svg');
    }
    &--favorites_active {
      background-image: url('../../public/assets/img/ico_favorite_primary.svg');
    }
  }
  &__item {
    @extend .text_s;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    background-color: $white;
    margin: 0;
    padding: 0;
    height: 62px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $grey4;
    cursor: pointer;
    &:hover {
      background-color: $white;
    }
    &.disable,
    &[disabled] {
      color: $grey2;
      background-color: $grey6;
      cursor: not-allowed;
      &:hover {
        opacity: 1;
      }
    }
    &--active {
      border-bottom: 1px solid $primary;
      color: $secondary;
    }

    &--featured {
      color: $primary;
      border-bottom: none;
      text-transform: uppercase;
    }
  }

  &__items_title {
    @extend .text_xxs;
    text-transform: capitalize;
    color: $text2;
    padding-top: $spacerm;
  }
}
