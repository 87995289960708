// desktop_building_visit_cta2

.desktop_building_visit_cta2 {
  margin: 0;
  padding: 0;
  &__title {
    @extend .title_heading_m;
    margin: 0 0 $spacers 0;
    padding: 0;
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    &__item {
      background-color: $grey6;
      background-image: url('../../public/assets/img/list_bg_sx2.svg'),
        url('../../public/assets/img/list_bg_dx2.svg');
      background-position: top left, top right;
      background-repeat: no-repeat, no-repeat;
      margin: 0 0 $spacerxxs2 0;
      padding: $spacerxxs2 $spacerxs2;
      border-radius: 4px;
      height: 48px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
      &__text {
        @extend %font-inter-bold;
        font-size: 12px;
        line-height: 20px;
        color: $grey1;
        margin: 7px 0 $spacerxs2 0;
        padding: 0;
        text-transform: uppercase;
        &--centered {
          text-align: center;
        }
      }
      &__button {
        float: right;
        width: 32px;
        height: 32px;
        cursor: pointer;
        outline: none;
        border: none;
        background: transparent url('../../public/assets/img/ico_arrow_right_blue.svg') no-repeat
          50% 50%;
        span {
          display: none;
        }
      }
      &--selected {
        border: 1px solid $primary;
      }
    }
  }
}
