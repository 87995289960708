.desktop-filters {
  position: relative;
  &__info {
    align-items: center;
    background: $background10 url('../../public/assets/img/bg_pattern.svg') repeat 0 0;
    min-height: 100px;

    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      padding: $spacerm $desktop-gutter;
      padding-bottom: 0;

      @include tablet {
        padding-left: $desktop-small-gutter;
        padding-right: $desktop-small-gutter;
      }
      @include smartphone {
        padding-left: $desktop-xsmall-gutter;
        padding-right: $desktop-xsmall-gutter;
      }
    }

    &__title {
      @extend .title_heading_s;
      margin-right: $spacers;

      @include tablet {
        font-size: 32px;
        line-height: 42px;
      }
      @include smartphone {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .button_chips {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }

  &__buttons {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: $spacers $desktop-gutter;
    div:last-child {
      margin-right: 0px;
    }
    @include tablet {
      padding-left: $desktop-small-gutter;
      padding-right: $desktop-small-gutter;
    }
    @include smartphone {
      padding-left: $desktop-xsmall-gutter;
      padding-right: $desktop-xsmall-gutter;
    }
    &--double_line {
      padding-top: 0;
      padding-bottom: 0;
    }
    &__wrapper {
      margin-right: 20px;
      margin-bottom: 20px;

      &__overlay {
        position: absolute;
        width: 250px;
        background: $white;
        border: 1px solid $grey4;
        box-sizing: border-box;
        @include box-shadow(z3);
        border-radius: 4px;
        margin-top: 12px;
        padding: 16px;
        z-index: 1;

        &__title {
          @extend .title_subheading_xs;
          color: $black_figma !important;
        }

        &__inputs {
          display: flex;

          div:first-child {
            margin-right: 20px;
          }

          &__container {
            display: flex;
            flex-direction: column;
          }
          &__label {
            @extend .title_subheading_xxs;
            flex-grow: 1;
            padding-left: 12px;
            padding-top: 20px;
            color: $text !important;
          }
          &__checkbox_label {
            margin-top: 7px;
          }

          &__input {
            @extend .input_style1;
            flex-grow: 1;
            margin-top: 8px;
            width: 100px;
          }
        }
        &__button {
          margin-top: 20px !important;
          margin-right: 20px !important;
        }
      }
    }
  }
  &__more_filters {
    border: none;
    border-radius: 4px;
    background-color: $chip_selected;
    color: $primary;
    font-size: 14px;
  }
}
