// auction

.auction_wrapper {
  background-color: $grey6;
}

.auction {
  background-color: $white;
  margin: 0 $spacerxs2 0 $spacerxs2;
  @include box-shadow(z2);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid $grey5;
  border-bottom: 0;
  &--no_border_radius {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
  }
  &__only_buy_now {
    border-bottom: 0;

    &--auction {
      margin-bottom: $spacers;

      .subtitle {
        align-self: center;
        margin-right: $spacerxxs2 !important;
        .info__calendar {
          color: $black_figma;
        }
      }

      .info {
        text-align: right;
        margin-left: $spacerxxs2 !important;
      }

      & + .auction {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        .buy_now_title {
          border-top: 1px solid $grey3;
        }
      }
    }
  }
  &__title {
    @extend .title_subheading_xxs;
    height: 28px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: $background3;
    margin-top: 0;
    padding: 4px 15px 0 17px;
  }
  &__details {
    padding: 10px 0 10px 0;
    &__row {
      display: flex;
      margin: 0;
      &.extra_margin {
        margin-top: $spacerxs2;
      }
      &.extra_padding {
        padding-top: $spacerxs2;
      }
      .subtitle {
        @extend .text_s;
        flex: 1 1 50%;
        margin-left: $spacerxs2;
        margin-right: $spacerxs2;
        color: $grey2;
        &--reduced {
          flex: 1 1 20%;
        }
      }
      .info {
        flex: 1 1 50%;
        margin-left: $spacerxs2;
        margin-right: $spacerxs2;
        color: $black_figma;
        &--reduced {
          flex: 1 1 20%;
        }
        &__date {
          @extend .title_number_s;
          margin: 0;
          background: transparent
            url('../../public/assets/img/ico_auction_calendar_grey.svg')
            no-repeat 0 50%;
          padding-left: 18px;
        }
        &__date-short {
          @extend .title_number_s;
          margin: 0;
          text-transform: capitalize;
        }
        &__calendar {
          @extend .text_xs;
          padding-left: 25px;
          background-repeat: no-repeat;
          background-image: url('../../public/assets/img/ico_calendar-purple.svg');
          background-position: 0 50%;
        }
        &__time {
          @extend .title_number_s;
          margin: 0;
          background: transparent
            url('../../public/assets/img/ico_auction_clock_grey.svg') no-repeat
            0 50%;
          padding-left: 18px;
        }
        &__price {
          @extend .title_number_s;
          margin: 0;
        }
        &__price2 {
          @extend .title_number_m;
          margin: 0;
        }
        &__price3 {
          @extend .title_number_l;
          margin: 0;
        }
        &__bid {
          @extend .title_number_s;
          margin: 0;
          color: $primary;
          cursor: pointer;
        }
      }
    }
    &__times {
      display: flex;

      .countdown_box--list > & {
        margin-top: 6px;
      }

      .time {
        min-width: 60px;
        text-align: center;
        .detail {
          @extend .title_heading_xxxs;
          text-transform: uppercase;
          color: $grey2;
        }
      }
    }
    &__reserved_price_reached {
      display: inline-block;
      @extend .text_xxs;
      height: 24px;
      margin: $spacerxxs2 $spacerxs2 $spacerxs2 $spacerxs2;
      padding: 2px $spacerxxs2;
      background-color: $background2;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 0.06em;
      color: $state1;
    }
  }
  &__buy_now {
    &__warning_button {
      @extend %font-inter-regular;
      font-size: 12px;
      line-height: 20px;
      color: $secondary;
      margin: $spacerxs2;
      padding: $spacerxxs2 $spacerxs2 $spacerxxs2 55px;
      border: none;
      outline: none;
      text-align: left;
      background: $background1 url('../../public/assets/img/ico_alert_blu.svg')
        no-repeat $spacerxs2 50%;
    }
    &__buy_button {
      @extend .button_primary;
      @extend %font-inter-bold;
      width: calc(100% - #{$spacerxs2} * 2);
      margin: $spacerxs2;
    }
  }
  &__bid_button {
    @extend .button_primary;
    @extend %font-inter-bold;
    margin: 0 $spacerxs2 $spacerxs2 $spacerxs2;
    width: calc(100% - #{$spacerxs2} * 2);
  }
  &__divider {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $grey4;
    margin: $spacerxs2 $spacerxs2 0 $spacerxs2;
    padding: 0;
    background-color: transparent;
  }
  .buy_now_title {
    @extend .title_subheading_xxs;
    height: 28px;
    background-color: $grey5;
    border-right: 1px solid $grey3;
    border-left: 1px solid $grey3;
    margin: 0;
    padding: 5px 16px 0 17px;
  }
  .only_buy_now_title {
    @extend .buy_now_title;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 0 !important;
  }
}
