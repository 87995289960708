
// stripe
.stripe_field {
  @extend .input_style1;
  padding: 15px;
  margin: 10px 0;

  &__group {
    display: flex;

    :first-child {
      margin-right: 12px;
    }

    &__col {
      flex: 1;

      &__subtitle {
        @extend .title_heading_xxs;
        margin-left: 10px;
        padding-top: 15px;
      }
    }

    &__element {
      @extend .stripe_field;
      margin: 10px 0;
      padding-top: 15px;
    }
  }

  &__extra_margin_bottom {
    margin-bottom: 30px;
  }
}

.stripe_field__input {
  @extend .input_style1;
  margin: 10px 0;
  width: 100%;
}

// buy now contact form
.buynow_field {
  @extend .input_style1;
  padding: 15px;
  margin: 10px 0;

  &__group {
    display: flex;

    :first-child {
      margin-right: 20px;
    }

    &__col {
      flex: 1;

      &__subtitle {
        @extend .title_heading_xxs;
        margin-left: 10px;
        padding-top: 15px;
      }
    }

    &__element {
      @extend .stripe_field;
      margin: 10px 0;
      padding-top: 15px;
    }
  }

  &__extra_margin_bottom {
    margin-bottom: 30px;
  }
}

.buynow_field__input {
  @extend .input_style1;
  margin: 10px 0;
  width: 100%;
}

.buynow_contact_form {
  input,
  select {
    @extend .buynow_field__input;
  }

  .hs-button {
    @extend .auction__bid_button;
    margin: 0;
    width: 100%;
    font-size: 0.7em;
  }

  .inputs-list {
    list-style-type: none;
    padding: 0;
  }

  input[type='checkbox'] {
    width: 10%;
    margin-right: 5%;
  }
}

// material ui
// svg.MuiSvgIcon-root {
//   fill: $primary;
// }

.MuiTooltip-popper {
  z-index: 10001 !important; // modal_aside_process__panel has 10000
}

.MuiTooltip-tooltip {
  // like title_subheading_xs
  font-family: 'Inter-Medium', Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  line-height: 24px !important;
  background-color: $background6 !important;
  color: $secondary !important;
}

.MuiTooltip-tooltip li {
  margin-left: 28px;
}

.MuiPopover-root {
  z-index: 10000002 !important;
}

.MuiPopover-paper {
  z-index: 10000002 !important;
}

.MuiFormControl-root.MuiTextField-root {
  width: 100%;

  .MuiInputBase-input {
    @extend .input_style1;
    border: none;
  }

  .MuiIconButton-label {
    .MuiSvgIcon-root {
      fill: $grey2;
    }
  }
}

// cookiebot
body #CybotCookiebotDialog .CybotCookiebotDialogContentWrapper {
  max-width: $layout-width !important;
}
body #CybotCookiebotDialogHeader {
  display: none;
}
body #CybotCookiebotDialog .CybotCookiebotDialogBodyBottomWrapper {
  display: none;
}
body #CybotCookiebotDialogBodyContentTitle {
  @extend .title_heading_m;
  margin: 0;
}
body #CybotCookiebotDialogBodyContentText p {
  @extend .text_s;
}
body .CookieDeclaration div,
body .CookieDeclaration p {
  @extend .text_m;
}

// Hubspot Chat
.hs-messages-mobile.hs-messages-widget-open
  body
  #hubspot-messages-iframe-container {
  bottom: 0 !important;
  max-height: 100% !important;
  z-index: 100000 !important;
}

body #hubspot-messages-iframe-container {
  bottom: $spacerxxl4 !important;
  max-height: calc(100% - #{$spacerxxl4}) !important;
  z-index: 9998 !important;
}

body.hide-chat #hubspot-messages-iframe-container {
  display: none !important;
}

// react-zoom-pan-pinch
.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.react-transform-component {
  width: 100% !important;
  height: 100% !important;
}
