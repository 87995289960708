// documents

.documents {
  background: $white url('../../public/assets/img/bg_pattern.svg') repeat 0 0;
  padding: $spacerm $spacerxs2;
  &__title {
    @extend .title_heading_m;
    margin: 0 0 $spacerxs2 0;
    padding: 0;
  }
  &__text {
    margin: 0 0 $spacerxs2 0;
    padding: 0;
    a:link,
    a:visited {
      @extend %font-inter-bold;
      font-size: 16px;
      line-height: 28px;
      text-transform: uppercase;
      color: $primary;
      letter-spacing: 0.1em;
    }
  }
  &__list {
    list-style: none;
    margin: 0 0 $spacerxs2 0;
    padding: 0;
    &__item {
      cursor: pointer;
      @extend .text_s;
      border: 1px solid $grey4;
      border-radius: 4px;
      margin: 0 0 $spacerxs2 0;
      padding: $spacerxs2 90px $spacerxs2 $spacerxs2;
      color: $text;
      background-color: $white;
      background-image: url('../../public/assets/img/documents_download.svg'),
        url('../../public/assets/img/documents_download_bg.svg');
      background-position: top 50% right 25px, top 50% right;
      background-repeat: no-repeat, no-repeat;
      &--disabled {
        background-image: url('../../public/assets/img/documents_download_disabled.svg'),
          url('../../public/assets/img/documents_download_bg_disabled.svg');
        cursor: not-allowed;
        color: $grey2;
      }
    }
  }
  &__button {
    @extend .button_tertiary;
    @extend .button_tertiary--small;
    @include button_ico(right, yes, arrow_down_blu_small);
    @extend %font-inter-bold;
  }
}

.widget_documents {
  padding: $spacerm $spacerxs2;
  background: #ffffff;
  width: 100%;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    /* Neutral/50 */
    background: #f5f5ff;
    min-height: 370px;
    &--disabled {
      opacity: 0.2;
      pointer-events: none;
    }
    &__sub {
      /* Auto layout */
      align-items: center;
      padding: 16px 8px;
      width: 100%;
      height: 248px;
      background: #ffffff;
      border-radius: 0px 0px 4px 4px;
      @extend %font-spacegrotesk-medium;
      font-style: normal;
      font-size: 16px;
      line-height: 24px;

      /* or 150% */
      text-align: center;
      letter-spacing: 0.004em;

      /* Neutral/500 */
      color: #8c8a9f;
    }

    .documents {
      &__text {
        font-size: 14px;
        a {
          font-size: 14px;
        }
      }
    }
  }
  &__title {
    @extend %font-inter-bold;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 0px 8px 16px;

    /* Opacity/Accent/400/10% */
    background: rgba(217, 39, 146, 0.1);
    border-radius: 4px 4px 0px 0px;
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    width: 100%;

    /* identical to box height, or 143% */
    letter-spacing: 0.004em;

    /* Neutral/900 */
    color: #131223;
  }
  &__text {
    @extend %font-inter-bold;
    margin: 0 0 $spacerxs2 0;
    padding: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;

    /* or 171% */
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Interactive/Blue light/700 */
    a:link,
    a:visited {
      color: $primary;
      letter-spacing: 0.1em;
    }
  }
  &__list {
    list-style: none;
    margin: 0 0 $spacerxs2 0;
    padding: 0;
    &__item {
      cursor: pointer;
      @extend .text_s;
      border: 1px solid $grey4;
      border-radius: 4px;
      margin: 0 0 $spacerxs2 0;
      padding: $spacerxs2 90px $spacerxs2 $spacerxs2;
      color: $text;
      background-color: $white;
      background-image: url('../../public/assets/img/documents_download.svg'),
        url('../../public/assets/img/documents_download_bg.svg');
      background-position: top 50% right 25px, top 50% right;
      background-repeat: no-repeat, no-repeat;
      &--disabled {
        background-image: url('../../public/assets/img/documents_download_disabled.svg'),
          url('../../public/assets/img/documents_download_bg_disabled.svg');
        cursor: not-allowed;
        color: $grey2;
      }
    }
  }
  &__button {
    @extend .button_tertiary;
    @extend .button_tertiary--small;
    @include button_ico(right, yes, arrow_down_blu_small);
    @extend %font-inter-bold;
  }
}
