// sticky_footer

.sticky_footer {
  @include box-shadow(z4);
  position: fixed;
  bottom: 0;
  left: 0;
  height: $sticky-footer-height;
  width: 100vw;
  background-color: $white;
  padding: $spacerxs2;
  z-index: 100;
  &__actions {
    $self: &;
    &__button1 {
      @extend .button_secondary;
      @extend %font-inter-bold;
      width: calc(50% - #{$spacerxs2} / 2);
      margin-right: $spacerxs2;
      text-align: center;
      //TODO Debito tecnico, vedere soluzione più coerente con il design
      padding-left: 0px;
      padding-right: 0px;
      @media only screen and (max-width: 366px) {
        span {
          font-size: 14px;
        }
      }
    }
    &__button2 {
      @extend %font-inter-bold;
      width: calc(50% - #{$spacerxs2} / 2);
      @media only screen and (max-width: 366px) {
        span {
          font-size: 14px;
        }
      }
    }
    &:not(#{$self}--type2) &__button2 {
      @extend .button_primary;
      @extend .button_primary--type3;
    }
    &--type2 {
      #{$self}__button1 {
        width: calc(40% - #{$spacerxs2} / 2);
      }
      #{$self}__button2 {
        width: calc(60% - #{$spacerxs2} / 2);
        @extend .button_primary;
      }
    }
  }
}

// This Block works with the BODY class "with_sticky_footer" - See global.scss line 30
