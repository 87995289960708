// map_overlay

.map_overlay {
  @include box-shadow(z3);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 86px;
  background-color: $white;
  margin: 0 $spacerxs2 0 $spacerxs2;
  &__header {
    @extend.clear_fix;
    position: sticky;
    background-color: $white;
    top: -1px;
    z-index: 1;
    &__close_button {
      @include button_ico(right, no, close_blue);
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      float: right;
    }
  }
  &__item {
    border-radius: 4px;
    margin: 0 $spacerxxs2 $spacerxs2 $spacerxxs2;
    padding: 0;
    border: 1px solid $grey5;
    display: flex;
    height: 126px;
    &__figure {
      flex: 0 0 auto;
      width: 130px;
      img {
        object-fit: cover;
        height: 124px;
      }
    }
    .building_pictures2 {
      &__auction_coming_soon,
      &__auction_running,
      &__auction_under_evaluation,
      &__auction_created,
      &__auction_closed,
      &__auction_lose,
      &__auction_win,
      &__auction_sold {
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;

        font-size: 10px;
        line-height: 12px;
      }
      &__picture {
        margin: 0;
      }
    }
    .building_pictures {
      &__social_actions {
        bottom: 0;
        left: 0;
        right: auto;
        &__item {
          margin-left: 0;
          margin-right: $spacerxxs2;
          &__button {
            background-color: transparent;
            background-image: url('../../public/assets/img/ico_hearth_white.svg');
            &--save_active {
              background-image: url('../../public/assets/img/ico_favorite_primary.svg');
            }
          }
        }
      }
    }
    &__info {
      flex: 1 1 auto;
      padding: $spacerxxs $spacerxxs2;
      &__title {
        @extend .title_heading_xxxs;
        margin: 0 0 3px 0;
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      &__address {
        @extend .property_detail__address;
        @extend .text_xxs;
        margin: 3px 0 3px 0;
        line-height: 1.3;
      }
      &__alert {
        @extend .text_xxs;
        margin: 0;
        padding: 0 0 0 20px;
        border: 1px solid $grey5;
        line-height: 13px;
        &--type1 {
          color: $state1;
          background: transparent
            url('../../public/assets/img/field_success2.svg') no-repeat 5px 50%;
        }
        &--type2 {
          color: $state2;
          background: transparent
            url('../../public/assets/img/field_warning2.svg') no-repeat 5px 50%;
        }
      }
    }
    .desktop_buildings_list__list__item__footer__list {
      margin: 0;
      padding: 0;
      span {
        @extend .title_number_s;
        margin-top: 0;
        font-size: 15px;
        &.small {
          font-size: 15px;
          margin-top: 0;
        }
      }
    }
  }
}
