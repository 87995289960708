// buildings_list

.buildings_list {
  padding: $spacerxs2;
  // until the mobile version uses the responsive desktop component, we can use it.
  .desktop_buildings_list__list {
    &__item {
      margin-bottom: $spacers;
      &__footer {
        height: auto;
      }
      .auction_block {
        .property_detail2__title {
          @extend .title_number_m;
          font-size: 24px;
          line-height: 32px;
          margin-bottom: $spacerxxs2;
          height: auto;
        }
      }
    }
  }
  &__switch_view {
    @extend %font-inter-bold;
    position: fixed;
    bottom: $spacerm;
    left: calc(50% - 45px);
    width: 90px;
    border: none;
    outline: none;
    background-color: $primary;
    background-repeat: no-repeat;
    background-position: 10px 50%;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    color: $white;
    padding: 3px 10px 3px 30px;
    border-radius: 4px;
    &--to_map {
      background-image: url('../../public/assets/img/ico_map_view.svg');
    }
    &--to_list {
      background-image: url('../../public/assets/img/ico_list_view.svg');
    }
  }
}
