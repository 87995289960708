// desktop_customer_feedback

.desktop_customer_feedback {
  background-color: $grey6;
  padding: $spacerxxl3 $desktop-gutter;
  @include tablet {
    padding-top: $spacerxxl;
    padding-bottom: $spacerxxl;
    padding-left: $desktop-small-gutter;
    padding-right: $desktop-small-gutter;
  }
  @include smartphone {
    padding-top: $spacerm;
    padding-bottom: $spacerm;
    padding-left: $desktop-xsmall-gutter;
    padding-right: $desktop-xsmall-gutter;
  }
  &__title {
    @extend .title_heading_l;
    text-align: center;
    margin: 0 0 $spacerm 0;
    padding: 0;
    @include smartphone {
      text-align: left;
      font-size: 24px;
      line-height: 32px;
    }
  }
  &__list {
    list-style: none;
    margin: 0 0 $spacerxxl2 0;
    padding: 0;
    &__item {
      @extend .text_s;
      font-style: italic;
      text-align: center;
      min-height: $spacerxxl2;
      @include smartphone {
        text-align: left;
      }
    }
  }
  &__navigator_list {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    &__item {
      display: inline-block;
      border-bottom: 2px solid $grey4;
      width: 24px;
      span {
        display: none;
      }
      &--active {
        border-color: $secondary;
      }
    }
  }
}
