// auction_block

.auction_block {
  margin: $spacerxs2;
  @include box-shadow(z2);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid $grey5;

  &--listed {
    margin: $spacerxs2 0;
  }
}
