.pdp_contact_form {
  display: flex;
  flex-flow: column;
  margin-bottom: $spacerxs2;
  .row {
    display: flex;
    flex-flow: row wrap;

    @include smartphone {
      flex-flow: column;
    }

    .col {
      flex: 1;
      margin: 0 $spacerxs2;
    }
  }

  &__button {
    @extend .button_primary;
    margin-top: $spacers;
  }

  &__success {
    @extend %font-inter-medium;
    margin: $spacerxxl3 $spacerxs2 0 $spacerxs2;
    display: flex;
    flex-flow: row;

    @include smartphone {
      margin: $spacerm $spacerxs2 $spacerxs2 $spacerxs2;
    }

    &__icon {
      width: 44px;
      height: 44px;
      background-color: transparent;
      background-size: contain;
      background-repeat: no-repeat;
      margin: auto $spacers auto 0;
      color: $state1;
      background-image: url('../../public/assets/img/field_success.svg');
    }

    &__text {
      flex: 1;
    }

    &__title {
      @extend .title_display_xs;
      color: $state1;
    }

    &__subtitle {
      @extend .text_s;
      line-height: 24px;
      color: $black;

      @include smartphone {
        font-size: 14px;
      }
    }
  }
}
