.sell_property {
  &__hero {
    @include tablet {
      background-color: #0d0068;
      margin-bottom: 18px;
      &__img {
        width: 100%;
      }
      &__search {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 18px 18px 18px;

        h1 {
          text-align: center;
          font-size: 20px;
          margin: 0 0 12px 0;
        }
      }
    }
  }
}
