// Variables

// Layout variables
$spacerxxs: 4px;
$spacerxxs2: 8px;
$spacerxs: 12px;
$spacerxs2: 16px;
$spacers: 24px;
$spacerm: 32px;
$spacerl: 40px;
$spacerxl: 48px;
$spacerxxl: 56px;
$spacerxxl2: 64px;
$spacerxxl3: 72px;
$spacerxxl4: 80px;

$layout-width: 1440px;
$layout_aside-width: 380px;
$layout_menu-width: 480px;
$layout-min-height: 70vh;

$main_header-height: 64px;
$main_desktop_header-height: 84px;
$main_desktop_submenu-height: 54px;
$footer-height: 64px;
$main_tab_container-margin-top: 30px;
$sticky-footer-height: 80px;
$sub_header_results-height: 40px;
$desktop-gutter: 120px;
$desktop-small-gutter: 56px;
$desktop-xsmall-gutter: 24px;
$desktop_sticky_footer_pdp-height: 80px;

$fadetime: 8s;

// Mixin
@mixin transition($valuetransition) {
  transition: $valuetransition;
  -webkit-transition: $valuetransition;
  -o-transition: $valuetransition;
  -moz-transition: $valuetransition;
}

@mixin transition-delay($valuetransition-delay) {
  transition-delay: $valuetransition-delay;
  -webkit-transition-delay: $valuetransition-delay;
}

@mixin transition-timing($valuetransition-timing) {
  transition-timing-function: $valuetransition-timing;
  -webkit-transition-timing-function: $valuetransition-timing;
  -moz-transition-timing-function: $valuetransition-timing;
  -o-transition-timing-function: $valuetransition-timing;
}

@mixin transition-duration($valuetransition-duration) {
  transition-duration: $valuetransition-duration;
  -webkit-transition-duration: $valuetransition-duration;
  -moz-transition-duration: $valuetransition-duration;
  -o-transition-duration: $valuetransition-duration;
}

@mixin transform-scale($valuetransform) {
  -ms-transform: scale($valuetransform);
  -webkit-transform: scale($valuetransform);
  -moz-transform: scale($valuetransform);
  -o-transform: scale($valuetransform);
  transform: scale($valuetransform);
}

@mixin hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

// Mediaquery & Browserquery
$xs-smartphone-width: 350px;
$s-smartphone-width: 430px;
$m-smartphone-width: 600px;
$m-tablet-width: 992px;
$s-desktop-width: 1024px;
$m-desktop-width: 1280px;
$l-desktop-width: 1441px;

@mixin xs-smartphone {
  @media (min-width: 0) and (max-width: #{$xs-smartphone-width}) {
    @content;
  }
}

@mixin s-smartphone {
  @media (min-width: 0) and (max-width: #{$s-smartphone-width}) {
    @content;
  }
}

@mixin smartphone {
  @media (min-width: 0) and (max-width: #{$m-smartphone-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 0) and (max-width: #{$m-tablet-width}) {
    @content;
  }
}

@mixin s-desktop {
  @media (min-width: 0) and (max-width: #{$s-desktop-width}) {
    @content;
  }
}

@mixin m-desktop {
  @media (min-width: 0) and (max-width: #{$m-desktop-width}) {
    @content;
  }
}

@mixin l-desktop {
  @media (min-width: #{$l-desktop-width}) {
    @content;
  }
}

@mixin no-desktop {
  @media (min-width: 993px) {
    @content;
  }
}

@mixin only-desktop {
  @media (min-width: $s-desktop-width) {
    @content;
  }
}

@mixin not-smartphone {
  @media (min-width: 800px) {
    @content;
  }
}

@mixin ie11-10 {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}
