// overlay_layer

$overlay_layer_header-height: 56px;
$overlay_layer_footer-height: 80px;

.overlay_layer {
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &__header {
    @include box-shadow(z2);
    padding: 0 $spacerxs2 0 $spacerxs2;
    height: $overlay_layer_header-height;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    &__close_button {
      float: left;
      border: none;
      outline: none;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-image: url('../../public/assets/img/ico_menu_close.svg');
      width: $spacerl;
      height: $spacerl;
      margin-top: 8px;
      span {
        display: none;
      }
    }
    &__title {
      @extend .title_heading_xs;
      margin: 0;
      padding: $spacerxs2 $spacerxl 0 $spacerxl;
      text-align: center;
    }
  }
  &__article {
    position: absolute;
    top: $overlay_layer_header-height;
    bottom: $overlay_layer_footer-height;
    width: 100%;
    overflow-y: auto;
  }
  &__footer {
    @include box-shadow(z2);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: $spacerxs2;
    height: $overlay_layer_footer-height;
    display: flex;
    &__button {
      flex: 1 1 auto;
      @extend %font-inter-bold;
      &--clear_button {
        @extend .button_secondary;
        margin-right: $spacerxs2;
      }
      &--apply_button {
        @extend .button_primary;
        @extend .button_primary--type3;
      }
    }
  }
}
