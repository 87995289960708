// desktop_timeline

.desktop_timeline {
  &__time_list {
    background-color: $grey5;
    list-style: none;
    margin: 0;
    padding: $spacerxs2 $desktop-gutter;
    display: flex;
    justify-content: center;
    @include s-desktop {
      padding-left: $desktop-small-gutter;
      padding-right: $desktop-small-gutter;
    }
    @include tablet {
      padding-left: $desktop-xsmall-gutter;
      padding-right: $desktop-xsmall-gutter;
    }
    @include smartphone {
      flex-wrap: wrap;
    }
    &__item {
      @extend .title_heading_xxxs;
      flex: 1 1 25%;
      margin: 0 0 0 0;
      padding: 0 $spacerxs2 35px $spacerxs2;
      color: $grey2;
      text-transform: uppercase;
      text-align: center;
      border-left: 4px solid $grey5;
      border-right: 4px solid $grey5;
      background: transparent url('../../public/assets/img/ico_pin_timeline.svg') no-repeat bottom
        center;
      @include tablet {
        background-position: top center;
        padding-top: $spacerxl;
        padding-bottom: $spacerxs2;
      }
      @include smartphone {
        flex: 0 0 50%;
      }
      &__description {
        display: none;
        @extend .text_xs;
        text-transform: none;
        @include tablet {
          display: block;
        }
        @include smartphone {
          word-break: break-word;
        }
      }
    }
  }
  &__text_list {
    background-color: $grey6;
    list-style: none;
    margin: 0;
    padding: $spacerxs2 $desktop-gutter $spacerxl $desktop-gutter;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @include s-desktop {
      padding-left: $desktop-small-gutter;
      padding-right: $desktop-small-gutter;
    }
    @include tablet {
      display: none;
    }
    &__item {
      @extend .text_xs;
      flex: 1 1 25%;
      margin: 0 0 0 0;
      padding: $spacerxs2;
      background-color: $grey5;
      color: $text;
      text-align: center;
      position: relative;
      border-left: 4px solid $grey6;
      border-right: 4px solid $grey6;
      &:before {
        content: '';
        display: block;
        height: 1px;
        background-color: $secondary;
        position: absolute;
        transform: rotate(90deg);
      }
      &:nth-child(odd) {
        margin-top: 68px;
        &:before {
          width: 100px;
          top: -50px;
          left: calc(50% - 50px);
        }
      }
      &:nth-child(even) {
        margin-top: 208px;
        &:before {
          width: 240px;
          top: -120px;
          left: calc(50% - 120px);
        }
      }
    }
  }
}
