.personal_auction_list {
  max-width: 552px;
  width: 100%;
  margin-bottom: 136px;
  &__header {
    height: 184px;
    display: flow-root;
    @include tablet {
      height: 101px;
    }
    @include smartphone {
      height: 101px;
    }
  }
  &__title {
    @extend .title_heading_s;
    margin: 95px 0 0 0;
    @include tablet {
      margin: $spacerxs2 0 0 0;
      padding-left: $desktop-small-gutter;
      padding-right: $desktop-small-gutter;
    }
    @include smartphone {
      margin: $spacerxs2 0 0 0;
      padding-left: $desktop-xsmall-gutter;
      padding-right: $desktop-xsmall-gutter;
    }
  }
  &__list {
    margin-top: $spacerm;
    @include tablet {
      padding-left: $desktop-small-gutter;
      padding-right: $desktop-small-gutter;
    }
    @include smartphone {
      padding-left: $desktop-xsmall-gutter;
      padding-right: $desktop-xsmall-gutter;
    }
  }
}
