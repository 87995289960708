.favorite_properties_list {
  max-width: 800px;
  width: 100%;
  &__header {
    height: 120px;
    display: flow-root;
    @include tablet {
      height: 120px;
    }
    @include smartphone {
      height: 60px;
    }
  }
  &__title {
    @extend .title_heading_s;
    margin: 95px 0 0 0;
    @include tablet {
      margin: $spacerxs2 0 0 0;
      padding-left: $desktop-small-gutter;
      padding-right: $desktop-small-gutter;
    }
    @include smartphone {
      margin: $spacerxs2 0 0 0;
      padding-left: $desktop-xsmall-gutter;
      padding-right: $desktop-xsmall-gutter;
    }
  }
  &__list {
    margin-top: $spacerm;
    @include tablet {
      padding-left: $desktop-small-gutter;
      padding-right: $desktop-small-gutter;
    }
    @include smartphone {
      padding-left: $desktop-xsmall-gutter;
      padding-right: $desktop-xsmall-gutter;
    }
  }
}
