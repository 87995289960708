.language_selector {
  list-style: none;
  margin-left: 14px;
  //margin-top: 8px;
  padding: 0;

  @include tablet {
    display: none;
  }

  &__item {
    min-width: 20px;
    align-items: center;
    width: auto;
    height: 15px;
    position: relative;
    cursor: pointer;
    @-moz-document url-prefix() {
      margin-top: 8px;
      padding: 0;
    }
  }

  &__menu {
    width: auto;
    padding: 0 8px;
    list-style: none;
    position: absolute;
    z-index: 1099;
    margin-top: 40px;
    border-radius: 8px;
    background-color: $white;
    @include box-shadow(z1);
    left: -$spacerxs2;
    &__item {
      margin: 16px 8px;
      padding: 0;
      cursor: pointer;
      width: 20px;
      height: 15px;
    }
  }
}
