.search_location {
  border: 1px solid $grey3;
  border-radius: 4px;
  background-color: $white;
  height: 48px;
  margin: 0;
  padding: 0;
  width: 600px;
  position: relative;
  @include smartphone {
    all: unset;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__input {
    @extend %font-inter-regular;
    height: 46px;
    border: none;
    background-color: $white;
    outline: none;
    color: $text;
    padding: 0 12px 0 12px;
    font-size: 14px;
    line-height: 24px;
    border-radius: 4px;
    width: calc(100% - 140px);
    @include smartphone {
      width: 100%;
    }
  }
  &__button {
    position: absolute;
    right: 0;
    @extend .button_primary;
    @extend .button_primary--type3;
    @extend %font-inter-bold;
    height: 46px;
    width: auto;
    background-repeat: no-repeat;
    background-position: 15px 50%;
    text-align: right;
    padding-top: 10px;
    padding-right: 14px;
    @include smartphone {
      margin-top: 16px;
      position: inherit;
      text-align: center;
    }
  }
}
