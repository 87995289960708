.auth-form {
    display: block;
    text-align: center;
    width: 100%;
    padding: 60px 0;
    @include smartphone {
      padding: 0;
    }
  
    &__wrapper {
      min-height: 100vh;
    }
    &__header-wrapper {
      position: relative;
      z-index: 2;
    }
    &__header-footer {
      position: relative;
      z-index: 2;
    }
    &__body {
      z-index: 1;
      min-height: calc(100vh - #{$footer-height+$main_header-height});
      background: $grey6 url('../../public/assets/img/modal_bg.svg') repeat 0 0;
      display: flow-root;
  
      @include smartphone {
        min-height: calc(100vh - 156px);
      }
    }
  
    &__panel {
      width: 720px;
      margin: 0 auto;
      background-color: $white;
      border-radius: 4px;
      @include box-shadow(z1);
      text-align: center;
  
      @include smartphone {
        width: calc(100% - 40px);
        margin: $spacers auto;
      }
    }
    &__content {
      width: 480px;
      text-align: left;
      margin: 0 auto;
      padding: 48px 0;
  
      @include smartphone {
        width: auto;
        padding: 16px 16px;
      }
    }
    &__title {
      @extend .title_heading_l;
      margin-bottom: $spacerm;
    }
    &__button {
      @extend .button_primary;
      width: 100%;
      &--accent {
        @extend .button_primary--type3;
      }
    }
    &__helper-a {
      margin-top: -$spacerxs2;
      text-align: right;
    }
    &__helper-b {
      text-align: right;
    }
    &__button-ancillary {
      @extend .button_tertiary;
    }
    &__helper-link {
      font-size: 14px;
      line-height: 24px;
      text-align: right;
      letter-spacing: 0.04em;
    }
    &__text {
      @extend .title_heading_xs;
    }
    &__divider {
      display: block;
      border-top: 1px solid $grey4;
      height: 0;
      border-bottom: 0;
      margin-top: $spacerxs2;
      margin-bottom: $spacerxs2;
    }
  }