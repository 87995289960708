@tailwind base;
@tailwind components;
@tailwind utilities;

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}
