// search_field

.search_field {
  border: 1px solid $grey3;
  border-radius: 4px;
  background-color: $white;
  height: 48px;
  margin: 0;
  padding: 0;
  width: 500px;

  &__anchor_fix {
    display: block;
    position: relative;
    top: -10px;
    visibility: hidden;
  }
  position: relative;
  @include smartphone {
    width: 100%;
  }
  &__input {
    @extend %font-inter-regular;
    height: 46px;
    border: none;
    background-color: $white;
    outline: none;
    color: $text;
    padding: 0 12px 0 12px;
    font-size: 14px;
    line-height: 24px;
    border-radius: 4px;
    width: calc(100% - 140px);
    @include smartphone {
      width: calc(100% - 48px);
    }
  }
  &__button {
    position: absolute;
    right: 0;
    @extend .button_primary;
    @extend .button_primary--type3;
    @extend %font-inter-bold;
    height: 46px;
    width: 140px;
    background-image: url('../../public/assets/img/ico_search_white.svg');
    background-repeat: no-repeat;
    background-position: 15px 50%;
    text-align: right;
    padding-top: 10px;
    padding-right: 14px;
    @include smartphone {
      width: 48px;
      background-position: 50% 50%;
    }
    span {
      @include smartphone {
        display: none;
      }
    }
  }
  &__dropdown {
    margin-top: $spacerxxs2;
    border: 1px solid $grey3;
    background-color: $white;
    border-radius: 4px;
    padding: $spacerxxs $spacerxs2 0 $spacerxs2;
    position: relative;
    z-index: 3;
    max-height: 210px;
    overflow-y: auto;
    &__title {
      @extend .title_heading_xxs;
      margin: 0;
      padding: 0;
    }
    &__list {
      list-style: none;
      margin: 0;
      padding: 0;
      &__item {
        font-size: 14px;
        border-bottom: 1px solid $grey5;
        padding: $spacerxxs2 0 $spacerxxs2 35px;
        background: transparent url('../../public/assets/img/ico_pin_blu.svg')
          no-repeat 0 50%;
        &:last-child {
          border-bottom: none;
          padding-bottom: none;
        }
        a {
          @include tablet {
            display: block;
          }
        }

        &__desc {
          color: #8c8a9f;
        }
      }
    }
  }
}
