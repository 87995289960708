// toast

.toast {
  max-width: 343px;
  left: 0;
  right: 0;
  bottom: -120px;
  margin: $spacerxs2 auto;
  padding: $spacerxxs2;
  border-radius: 2px;
  background-color: $white;
  background-position: $spacerxs2 50%;
  background-repeat: no-repeat;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.8px;

  position: fixed;
  transition: bottom 3s;
  z-index: 1000;

  &__message {
    margin: 0;
    padding: 0 0 0 $spacerxl;
  }
  &--feedback_error {
    background-image: url('../../public/assets/img/toast_feedback_ko.svg');
    background-color: $state3;
    color: $white;
  }
  &--feedback_ok {
    background-image: url('../../public/assets/img/toast_feedback_ok.svg');
    background-color: $state8;
    color: $white;
  }
  &--feedback_email {
    background-image: url('../../public/assets/img/toast_feedback_email.svg');
    background-color: $grey4;
    color: $secondary;
  }
  &--show {
    bottom: $spacerxxl2;
  }
}
