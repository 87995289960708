// stepper

.stepper {
  background-color: $grey6;
  margin: 0 0 $spacerxs2 0;
  padding: $spacerxs2;
  overflow: hidden;
  &__list {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    &__item {
      $self: &;
      flex: 0 0 80px;
      @extend .title_heading_xxxs;
      text-transform: uppercase;
      color: $grey3;
      text-align: center;
      position: relative;
      &__description {
        margin: 0;
        padding: 0;
        text-transform: none;
        color: $grey2;
      }
      &::before {
        content: '';
        display: block;
        height: 32px;
        width: 32px;
        margin-left: $spacers;
        margin-bottom: $spacerxxs2;
        background-color: $white;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-image: url('../../public/assets/img/stepper_step.svg');
        border-radius: 50%;
      }
      &--active {
        #{$self}__description {
          color: $text;
        }
        &::before {
          background-image: url('../../public/assets/img/stepper_current_step.svg');
        }
      }
      &--done {
        #{$self}__description {
          color: $text;
        }
        &::before {
          background-image: url('../../public/assets/img/stepper_step_done.svg');
          background-color: $primary;
        }
      }
      &:after {
        content: '';
        display: block;
        border-bottom: 2px solid $grey5;
        height: 1px;
        width: 100vw;
        position: absolute;
        top: 15px;
        left: 56px;
      }
      &:nth-child(2) {
        &:after {
          display: none;
        }
      }
      &:last-child {
        &:after {
          border-color: $grey6;
        }
      }
    }
  }
}
