.widget_property {
  &__header {
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $grey3;
      border: 0px !important;
      &:nth-child(2) {
        border-bottom: none;
      }
      li {
        @extend .title_subheading_xxs;
        flex: 0 0 auto;
        color: $grey2;
        span {
          @extend .title_number_m;
          display: block;
          color: $text;
          margin-top: $spacerxxs;
          &.small {
            @extend .title_number_s;
            margin-top: 11px;
          }
          &.bids {
            text-align: right;
          }
        }
      }
    }

    &__code {
      @extend %font-spacegrotesk-bold;
      padding: 4px;
      width: fit-content;
      background: rgba(40, 36, 229, 0.1);
      border-radius: 4px;
      margin: 4px 0 0 0px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.008em;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: rgba(13, 0, 104, 0.4);
    }
  }

  &__toplabel {
    @extend %font-inter-bold;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.004em;
    color: $state4;
  }
  &__pricelabel {
    @extend %font-spacegrotesk-bold;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.004em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $text;
  }

  &__tab {
    border-bottom: 1px solid $grey5;
    margin: 8px 0 0 0;
    height: 20px;
    display: flex;
    justify-content: space-between;
    padding: 0;

    &__item {
      text-align: center;
      width: 100%;
      // align-self: flex-end;
      list-style-type: none;
      @extend %font-inter-medium;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;

      /* identical to box height, or 171% */
      letter-spacing: 0.1em;
      text-transform: uppercase;
      text-decoration: none;

      position: relative;
      border-bottom: 2px solid transparent;

      /* Neutral/500 */
      color: $state4;

      &:hover {
        cursor: pointer;
      }

      &--selected {
        color: $primary;
        border-bottom: 2px solid $primary;

        &::before {
          background-color: $primary;
        }
      }

      > span {
        margin-top: auto;
        margin-bottom: 0;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}

//STRIPE BUY NOW
.tab-content *.stripe_field__group {
  @include s-smartphone {
    display: flex;
    flex-direction: column;
    .stripe_field__group__col {
      margin-right: 0px;
    }
  }
}

//OFFERTA COMPLETATA
.tab-content *.bid_done__title {
  background-image: none;
  padding-top: 32px;
}
.tab-content *.bid_done__price {
  margin-bottom: 32px;
}

//DOCUMENTS MODAL
.tab-content *.modal {
  padding-top: 0px;
  h1 {
    padding-top: 32px;
  }
}
