// desktop_building_exploration

.desktop_building_exploration {
  padding: $spacerm $desktop-gutter;

  @include tablet {
    padding-top: $spacerxxl;
    padding-bottom: $spacerxxl;
    padding-left: $desktop-small-gutter;
    padding-right: $desktop-small-gutter;
  }
  @include smartphone {
    padding-top: $spacerm;
    padding-bottom: 0;
    padding-left: $desktop-xsmall-gutter;
    padding-right: $desktop-xsmall-gutter;
  }
  &__list {
    list-style: none;
    margin: 0;
    @include hide-scrollbar;
    overflow-x: scroll;
    padding: 0;
    display: flex;
    padding-bottom: $spacers;
    &__item {
      @extend .title_heading_s;
      background-color: $white;
      border-radius: 4px;
      text-align: center;
      min-width: 29%;
      flex: 1 1 auto;
      margin-right: 32px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      > div {
        margin-bottom: $spacerxs2 !important; // fix for NextJs
      }
      img {
        max-width: 100%;
        display: block;
        margin-bottom: $spacerxs2;
      }
      span {
        @extend .title_subheading_xs;
        display: block;
      }
      &:hover {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 16px;
      }
      @include tablet {
        min-width: 45%;
        margin-right: 16px;
      }
      @include smartphone {
        min-width: 88%;
        margin-right: 16px;
      }
      &__name {
        justify-content: center;

        padding-top: 8px;
      }
    }
  }
}
