// modal_confirm

.modal_confirm {
  width: 480px;
  position: relative;
  background-color: $white;
  @include smartphone {
    width: 98%;
    margin: auto;
    top: $spacerxxl2;
  }
  &__header {
    @include box-shadow(z1);
    height: $spacerxxl;
    &__close_button {
      cursor: pointer;
      position: absolute;
      top: $spacerxxs2;
      right: $spacerxxs2;
      border: none;
      outline: none;
      width: 40px;
      height: 40px;
      background: transparent url('../../public/assets/img/modal_close.svg') no-repeat 50% 50%;
      background-size: 18px;
      span {
        display: none;
      }
    }
    &__title {
      @extend .title_heading_s;
      margin: 0;
      padding: $spacerxs $spacerxs2 0 $spacerxs2;
      text-align: center;
      @include smartphone {
        text-align: left;
      }
    }
  }
  &__article {
    padding: $spacerm;
    &__title {
      @extend .title_heading_m;
      margin: 0 0 50px 0;
      padding: 0;
      text-align: center;
    }
    &__actions {
      display: flex;
      button {
        @extend %font-inter-bold;
        flex: 1 1 auto;
        &.close {
          margin-right: $spacerxs2;
          @extend .button_secondary;
        }
        &.confirm {
          @extend .button_primary;
          @extend .button_primary--type3;
        }
      }
    }
  }
}
