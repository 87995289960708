// sub_header_results

.sub_header_results {
  @include box-shadow(z5);
  height: $sub_header_results-height;
  padding: 0 $spacerxs2 0 $spacerxs2;
  display: flex;
  align-items: center;
  background-color: $white;
  &__text {
    @extend .title_heading_xxxs;
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
  }
  &__actions {
    flex: 1 1 auto;
    list-style: none;
    margin: 0;
    padding: 0;
    &__item {
      float: right;
      &__button {
        border: none;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        outline: none;
        height: $sub_header_results-height;
        width: 30px;
        &--filter {
          background-image: url('../../public/assets/img/ico_filter_darkblue.svg');
          &--active {
            background-image: url('../../public/assets/img/ico_filter_active_darkblue.svg');
          }
        }
        &--order {
          background-image: url('../../public/assets/img/ico_order_darkblue.svg');

          &--desc {
            background-image: url('../../public/assets/img/ico_order_darkblue.svg');
            transform: rotate(180deg);
          }
        }
        span {
          display: none;
        }
      }
      &__filter_value {
        @extend .title_heading_xxxs;
        margin: $spacerxs 0 0 0;
        padding: 0;
        color: $grey2;
      }
    }
  }
}
