// desktop_text_block_50

.desktop_text_block_50 {
  padding: $spacerxs2 $desktop-gutter;
  background-color: $white;
  @include m-desktop {
    padding-left: $desktop-small-gutter;
    padding-right: $desktop-small-gutter;
  }
  @include tablet {
    padding: $spacerm $desktop-xsmall-gutter;
  }
  &__list {
    @extend .clear_fix;
    list-style: none;
    margin: 0;
    padding: 0;
    &__item {
      width: calc(50% - #{$spacers});
      float: left;
      @include tablet {
        width: 100%;
        float: none;
      }
      &:first-child {
        margin-right: $spacers;
        @include tablet {
          margin-right: 0;
        }
      }
      &:last-child {
        margin-left: $spacers;
        @include tablet {
          margin-left: 0;
        }
      }
      img {
        float: left;
        margin-right: $spacers;
        @include m-desktop {
          float: none;
          margin-bottom: $spacerxs2;
        }
      }
      &__title {
        @extend .title_heading_l;
        margin: 0 0 $spacerxs2 0;
        padding: 0;
        float: left;
        width: calc(100% - 310px);
        @include m-desktop {
          float: none;
          width: 100%;
          margin-bottom: $spacerxxs2;
        }
        &:before {
          content: '';
          display: block;
          height: 1px;
          width: 72px;
          background-color: $grey2;
          margin-bottom: $spacerxxs2;
        }
      }
      &__subtitle {
        @extend .title_subheading_m;
        margin: 0 0 $spacerxs2 0;
        padding: 0;
        color: $grey2;
        float: none;
        @include m-desktop {
          margin-bottom: $spacerxxs2;
        }
      }
      &__text {
        @extend .text_m;
        margin: 0;
        padding: 0;
        @include m-desktop {
          margin-bottom: $spacerm;
        }
      }
    }
  }
}
