.personal_auction_bids {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1px auto auto auto auto;
  grid-template-areas:
    'divider divider'
    'lastOffer myBail'
    'myOfferStatus myOfferStatus'
    'lastOfferTime toggler'
    'myOfferList myOfferList';
  grid-gap: $spacerxxs2;
  &__divider_container {
    grid-area: divider;
  }
  &__divider {
    margin: 0 $spacerxs2;
    height: 1px;
    background-color: $grey4;
  }
  &__last_offer {
    grid-area: lastOffer;
    margin-left: $spacerxs2;
  }
  &__last_offer_label {
    @extend .title_heading_xxs;
    display: block;
    color: $grey2;
  }
  &__last_offer_value {
    @extend .title_number_l;
    display: block;
    color: $accent;
    margin-top: $spacerxxs2;
  }
  &__deposit {
    grid-area: myBail;
    margin-right: $spacerxs2;
    text-align: right;
  }
  &__deposit_label {
    display: inline-block;
    @extend .text_xxs;
    text-transform: uppercase;
    margin: 0;
    border-radius: 2px;
    line-height: 1.3;
    padding: 0px $spacerxxs 0px $spacerxs2;
    background-repeat: no-repeat;
    background-position: center left;

    &--active {
      color: $secondary;
      background-color: $background8;
      background-image: url('../../public/assets/img/ico_bail_feedback_active.svg');
    }
    &--ok {
      color: $state1;
      background-color: $state9;
      background-image: url('../../public/assets/img/ico_bail_feedback_ok.svg');
    }
    &--ko {
      color: $state2;
      background-color: $background_error;
      background-image: url('../../public/assets/img/ico_bail_feedback_ko.svg');
    }
  }
  &__deposit_value {
    display: block;
    @extend .title_number_s;
    padding: $spacerxs2;
  }
  &__my_offer_status {
    grid-area: myOfferStatus;
    margin-left: $spacerxs2;
    margin-right: $spacerxs2;
  }
  &__my_offer_status_label {
    display: inline-block;
    @extend .text_xxs;
    padding: 0px $spacerxxs 0px $spacerxs2;
    background-repeat: no-repeat;
    background-position: center left;
    &--ok {
      color: $state1;
      background-image: url('../../public/assets/img/ico_bail_feedback_ok.svg');
    }
    &--ko {
      color: $state2;
      background-image: url('../../public/assets/img/ico_bail_feedback_ko.svg');
    }
  }
  &__last_offer_time {
    grid-area: lastOfferTime;
    margin-left: $spacerxs2;
  }
  &__toggler {
    grid-area: toggler;
    align-self: end;
    text-align: right;
    margin-right: $spacerxs2;
    @extend .button_link;
    color: $primary;
    padding: 0px $spacerxs2 0px $spacerxxs;
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url('../../public/assets/img/ico_bail_toggler_closed.svg');
    &--toggled {
      background-image: url('../../public/assets/img/ico_bail_toggler_open.svg');
    }
  }
  &__list {
    grid-area: myOfferList;
    padding: $spacerxs2;
    background-color: $grey6;
  }

  &__table {
    width: 100%;
  }
  &__table_data {
    border-top: 1px solid $grey5;
    text-align: left;
    @extend .title_heading_xxxs;
    line-height: $spacers;
  }
  &__automatic {
    @extend .button_link;
    color: $primary;
    padding: 0px $spacerxs2 0px $spacerxxs;
  }
  &__table_header {
    text-align: left;
    @extend .text_xxs;
  }
}
