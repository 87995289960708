// countdown_box

.countdown_box {
  margin: 0;
  padding: $spacerxxs2 $spacerxs2;
  background-color: $state7;
  margin: 0 $spacerxs2;
  &--list {
    background-color: transparent;
    margin: 0 0;
  }
  &__title {
    @extend .title_subheading_xxs;
    color: $grey2;
    margin: 0;
    padding: 0;
  }
}
