// desktop_building_characteristics

.desktop_building_characteristics {
  @extend .clear_fix;
  background-color: $white;
  margin: 0 0 $spacerxxl2 0;
  padding: $spacerxs2 0 $spacerxxl $desktop-gutter;
  border-bottom: 1px solid $grey4;

  &--type2 {
    border-bottom: none;
  }
  &__column1 {
    width: calc(50% - #{$spacerxs2});
    float: left;
    display: flex;
    flex-direction: column;
    margin-right: $spacerxs2;
    @include m-desktop {
      width: 100%;
      float: none;
      margin-right: 0;
    }
  }
  &__column2 {
    width: 50%;
    float: right;
    display: flex;
    flex-direction: column;
    @include m-desktop {
      width: 100%;
      float: none;
    }
  }
  &__row {
    display: flex;
    margin: $spacerxs2 0 $spacerxs2 0;
    .info {
      flex: 1 1 50%;
      color: $black_figma;
      &__text {
        @extend .title_subheading_xs;
        margin: 0;
        color: $text2;
        white-space: pre-line;
      }
      &__value {
        @extend .title_number_s;
        font-weight: bold;
        margin: 0;
      }
    }
  }
}
