// Material Redefinitions

// Date Picker
.MuiPaper-root {
  .MuiPickersBasePicker-container {
    .MuiPickersDay-daySelected {
      background-color: $primary;
    }
    .MuiPickersToolbar-toolbar {
      background-color: $primary;
    }
    .MuiPickersBasePicker-pickerView {
      .MuiPickersCalendarHeader-switchHeader {
        .MuiIconButton-label {
          .MuiSvgIcon-root {
            fill: $primary;
          }
        }
      }
    }
  }
}
