// desktop_documents

.desktop_documents {
  background: $white url('../../public/assets/img/bg_pattern.svg') repeat 0 0;
  margin: 0 0 $spacerxl 0;

  padding: 16px;
  // padding: $spacerm $spacerxxl $spacerm $desktop-gutter;

  &__title {
    @extend .title_heading_m;
    margin: 0 0 $spacerxs2 0;
    padding: 0;
  }
  &__text {
    margin: 0 0 $spacerxs2 0;
    padding: 0;
    a,
    a:link,
    a:visited {
      @extend %font-inter-bold;
      font-size: 16px;
      line-height: 28px;
      text-transform: uppercase;
      color: $primary;
      letter-spacing: 0.1em;
    }
  }
  &__list {
    list-style: none;
    margin: 0 0 $spacerxs2 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    &__item {
      @extend .text_s;
      cursor: pointer;
      flex: 0 0 45%;
      border: 1px solid $grey4;
      border-radius: 4px;
      margin: 0 $spacerm $spacerxs2 0;
      padding: $spacerxs2 90px $spacerxs2 $spacerxs2;
      color: $text;
      background-color: $white;
      background-image: url('../../public/assets/img/documents_download.svg'),
        url('../../public/assets/img/documents_download_bg.svg');
      background-position: top 50% right 25px, top 50% right;
      background-repeat: no-repeat, no-repeat;
      @include m-desktop {
        flex: 0 0 100%;
      }
      &--disabled {
        background-image: url('../../public/assets/img/documents_download_disabled.svg'),
          url('../../public/assets/img/documents_download_bg_disabled.svg');
        cursor: not-allowed;
        color: $grey2;
      }
    }
  }
  &__button {
    @extend .button_tertiary;
    @extend .button_tertiary--small;
    @include button_ico(right, yes, arrow_down_blu_small);
    @extend %font-inter-bold;
  }
}
