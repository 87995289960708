// contact form

.contact_form {
  //background-color: $grey6;

  &__title {
    @extend .title_display_m;
    margin: 5px 0 10px 0;
    padding: 0;
    color: $black_figma;
  }

  input,
  select,
  textarea {
    @extend .input_style1;

    margin: 10px 0;
    width: 100%;
  }

  .hs-button {
    @extend .auction__bid_button;
    margin: 0;
    width: 100%;
    font-size: 0.7em;
  }

  .inputs-list {
    list-style-type: none;
    padding: 0;
  }

  input[type='checkbox'] {
    width: 10%;
    margin-right: 5%;
  }

  /* pretty radio */
  label > input[type='radio'] {
    display: none;
  }

  label > input[type='radio'] + *::before {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.1rem;
    border-color: $primary;
    padding-bottom: 0 !important;
    line-height: 1em !important;
  }

  label > input[type='radio'] + * {
    padding-bottom: 0 !important;
    line-height: 1em !important;
    padding-left: 0 !important;
  }

  label > input[type='radio']:checked + *::before {
    background: radial-gradient($primary 0%, $primary 50%, transparent 0%, transparent);
    border-color: $primary;
    padding-bottom: 0 !important;
    line-height: 1em !important;
  }

  /* basic layout */
  fieldset {
    margin: 20px;
    max-width: 400px;
  }

  label > input[type='radio'] + * {
    display: inline-block;
    padding: 0.5rem 1rem;
  }

  textarea[name='message'] {
    height: 10em;
    resize: none;
  }

  input[type='submit'] {
    @extend .button_primary;
  }

  input[type='checkbox'] {
    padding: 0;
    margin: 0;
    width: 26px;
    height: 26px;
    border: 2px solid $primary;
    border-radius: 0;
    background-color: transparent;
    opacity: 0;
    left: -3px;
    float: none;
    position: relative;
    display: inline-block;
  }

  .hs-form-booleancheckbox-display:before {
    content: '';
    padding: 0;
    margin: 0;
    left: -27px;
    width: 20px;
    height: 20px;
    border: 2px solid $primary;
    border-radius: 0;
    background-color: transparent;
    position: relative;
    display: inline-block;
  }

  .hs-form-booleancheckbox-display > input[type='checkbox'] + span:before {
    content: '';
    padding: 0;
    margin: 0;
    left: -25px;
    top: -31px;
    width: 18px;
    height: 18px;
    border-radius: 0;
    background-color: transparent;
    position: relative;
    display: inline-block;
  }

  .hs-form-field {
    margin-top: 10px;
  }

  .hs-form-field > label {
    font-weight: bold;
    padding-left: 15px;
  }

  .hs-form-booleancheckbox-display > input[type='checkbox']:checked + span:before {
    content: '';
    padding: 0;
    margin: 0;
    left: -18px;
    top: -31px;
    width: 18px;
    height: 18px;
    border-radius: 0;
    background-color: $primary;
    position: relative;
    display: inline-block;
  }

  label.hs-form-booleancheckbox-display span .hs-form-required {
    position: relative;
    top: -30px;
  }

  label.hs-form-booleancheckbox-display span p {
    position: relative;
    top: -30px;
  }

  div.legal-consent-container div.hs-richtext {
    position: relative;
    top: -30px;
  }

  .hs-error-msgs {
    @extend .input_style1--error;
    position: relative;
    top: -30px;
  }

  input.invalid.error {
    @extend .input_style1--error;
  }
}
