.stepper_line {
  &__container {
    display: flex;
    justify-content: center;
    padding: 0 8px;

    @include only-desktop {
      margin-bottom: 16px;
    }

    &__step {
      width: 150%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: #dddde7;
      &:first-child {
        width: 150%;
        align-items: flex-start;
      }
      &__dot {
        padding: 0;
        //background: #2824e5;
        background-color: #ffffff;
        /* Base/White */
        border-radius: 100%;
        border: 2px solid #dddde7;
        width: 20px;
        height: 20px;
        z-index: 99;
      }

      &__number {
        @extend %font-inter-regular;
        padding-left: 6px;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        position: relative;
        top: -17px;
        z-index: 99;
      }

      &--prev {
        color: white;
        .stepper_line__container__step__dot {
          background-color: #2824e5;
          border-color: #2824e5;
        }

        .stepper_line__container__step__line {
          border-color: #2824e5;
        }
      }

      &--active {
        color: #d92792;
        .stepper_line__container__step__dot {
          background-color: #ffffff;
          border-color: #d92792;
          /* Base/Blue */
        }
      }

      &:last-child {
        width: 16px;
        .stepper_line__container__step__line {
          display: none;
        }
      }

      &__line {
        position: relative;
        width: 100%;
        border-bottom: 2px solid #dddde7;
        top: -26px;
      }
    }
  }
}
