// main_header

.main_header {
  $self: &;
  height: $main_header-height;
  padding-left: $spacerxs;
  padding-right: $spacerxs;
  display: flex;
  align-items: center;
  background-color: $white;
  &--shadow {
    @include box-shadow(z1);
  }

  &--type2 {
    background-color: $secondary;

    .main_header__back {
      background-image: url('../../public/assets/img/ico_menu_back_white.svg');
    }
  }

  &--border {
    border-bottom: 1px solid $grey5;
  }
  &--search_field_opened {
    #{$self}__search {
      display: none;
    }
    #{$self}__logo {
      display: none;
    }
    #{$self}__logo_reduced {
      display: block;
    }
    #{$self}__field {
      display: block;
    }
  }
  &__hamburger_menu {
    @include button_ico(left, no, hamburger);
    background-color: transparent;
    border: none;
    outline: none;
    flex: 0 0 auto;
    width: 44px;
    height: 48px;
  }
  &__back {
    @include button_ico(left, no, back);
    background-color: transparent;
    border: none;
    outline: none;
    flex: 0 0 auto;
    width: 44px;
    height: 48px;
  }
  &__close {
    @include button_ico(left, no, close);
    background-color: transparent;
    border: none;
    outline: none;
    flex: 0 0 auto;
    width: 44px;
    height: 48px;
  }
  &__logo {
    flex: 1 1 auto;
    height: 32px;
    text-align: center;
    cursor: pointer;
    &--type2 {
      margin-right: 45px;
      height: 50px;
    }
  }
  &__logo_reduced {
    flex: 0 0 auto;
    height: 48px;
    text-align: center;
    cursor: pointer;
    display: none;
  }
  &__search {
    @include button_ico(left, no, search);
    background-color: transparent;
    border: none;
    outline: none;
    flex: 0 0 auto;
    width: 44px;
    height: 48px;
  }
  &__user {
    //@include button_ico(left, no, user); // remove comment when the icon is needed
    background-color: transparent;
    border: none;
    outline: none;
    flex: 0 0 auto;
    width: 44px;
    height: 48px;
  }
  &__title {
    color: $text;
    @extend .title_heading_xs;
    flex: 1 1 auto;
    margin-right: 45px;
    height: 24px;
    text-align: center;
    cursor: pointer;
  }
  &__field {
    display: none;
    flex: 1 1 auto;
    border: 1px solid $grey3;
    border-radius: 4px;
    background-color: $white;
    height: 48px;
    margin: 0;
    padding: 0;
    position: relative;
    &__input {
      @extend %font-inter-regular;
      height: 46px;
      border: none;
      background-color: $white;
      outline: none;
      color: $text;
      padding: 0 12px 0 12px;
      font-size: 14px;
      line-height: 24px;
      border-radius: 4px;
      width: calc(100% - 48px);
    }
    &__button {
      position: absolute;
      right: 0;
      @extend .button_primary;
      @extend %font-inter-bold;
      height: 46px;
      width: 48px;
      background-image: url('../../public/assets/img/ico_search_white.svg');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 34px;
      text-align: right;
      padding-top: 10px;
      padding-right: 14px;
      span {
        display: none;
      }
    }
  }
}
