// desktop_sticky_footer_pdp

.desktop_sticky_footer_pdp {
  @include transition(0.4s);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  opacity: 0;
  z-index: 10;
  &--visible {
    opacity: 1;
    height: $desktop_sticky_footer_pdp-height;
  }
  &__inner_wrapper {
    height: $desktop_sticky_footer_pdp-height;
    max-width: $layout-width;
    margin: auto;
    padding: $spacerxs2 $spacerm $spacerxs2 $spacerm;
    background-color: $secondary;
    color: $white;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    &__button {
      @extend .button_primary;
      @extend %font-inter-bold;
      float: left;
      width: 310px;
      @include m-desktop {
        width: 260px;
      }
      &--cta1 {
        @extend .button_primary--type3;
        margin-right: $spacerxs2;
      }
    }
    &__tel {
      @extend .title_number_m;
      color: $white;
      float: right;
      margin: $spacerxxs2 0 0 0;
      padding: 0 0 0 30px;
      background: transparent
        url('../../public/assets/img/menu_footer_phone_white.svg') no-repeat 0
        50%;
      a:link,
      a:visited {
        color: $white;
      }
    }
  }
}
