// desktop_main_header

.desktop_main_header {
  $self: &;
  background-color: $white;
  height: $main_desktop_header-height;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: $desktop-gutter;
  padding-right: $desktop-gutter;
  position: relative;
  @include m-desktop {
    padding-left: $desktop-small-gutter;
    padding-right: $desktop-small-gutter;
  }
  &--shadow {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      @include box-shadow(z1);
    }
  }
  &--type2 {
    #{$self}__menu {
      @include m-desktop {
        display: none;
      }
    }
    #{$self}__field {
      @include m-desktop {
        margin-right: 10%;
      }
    }

    #{$self}__hamburger_menu,
    #{$self}__back {
      @include m-desktop {
        display: block;
      }
    }
  }
  &__back,
  &__hamburger_menu {
    display: none;
    flex: 0 0 auto;
    width: 48px;
    height: 48px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-right: 8px;
    span {
      display: none;
    }
  }
  &__back {
    background: transparent url('../../public/assets/img/ico_menu_close.svg')
      no-repeat 50% 50%;
  }
  &__hamburger_menu {
    background: transparent
      url('../../public/assets/img/ico_menu_hamburger.svg') no-repeat 50% 50%;
  }
  &__logo {
    flex: 0 0 auto;
    height: 34px;
    margin-right: 40px;
    cursor: pointer;
  }
  &__field {
    flex: 1 1 16%;
    border: 1px solid $grey3;
    border-radius: 4px;
    background-color: $white;
    height: 48px;
    margin: 0;
    padding: 0;
    //width: 500px;
    position: relative;
    &__input {
      @extend %font-inter-regular;
      height: 46px;
      border: none;
      background-color: $white;
      outline: none;
      color: $text;
      padding: 0 12px 0 12px;
      font-size: 14px;
      line-height: 24px;
      border-radius: 4px;
      width: calc(100% - 48px);
    }
    &__button {
      position: absolute;
      right: 0;
      @extend .button_primary;
      @extend %font-inter-bold;
      height: 46px;
      width: 48px;
      background-image: url('../../public/assets/img/ico_search_white.svg');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      text-align: right;
      padding-top: 10px;
      padding-right: 14px;
      span {
        display: none;
      }
    }
  }
  &__menu {
    flex: 1 1 auto;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right;
    &__item {
      @extend %font-inter-bold;
      display: inline-block;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
      margin: 0 $spacerxs2 0 $spacerxs2;
      @include m-desktop {
        font-size: 13px;
      }
      @include s-desktop {
        font-size: 12px;
        margin-left: $spacerxxs2;
        margin-left: $spacerxxs2;
      }
      a:link,
      a:visited {
        color: $grey1;
      }
      &--phone_number {
        @extend %font-inter-regular;
        background: transparent
          url('../../public/assets/img/menu_footer_phone.svg') no-repeat 0 50%;
        padding-left: 25px;
        a:link,
        a:visited {
          color: $primary;
        }
      }
    }
  }
  &__actions {
    //flex: 0 0 150px;
    flex: 0 0 85px; // Temporaneo fino che le action non si completano con tutte le funzioni
    list-style: none;
    margin: 0 0 8px 0;
    padding: 0;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &__item {
      width: 32px;
      height: 36px;
      margin: 10px $spacerxxs 0 $spacerxxs;
      &__button {
        border: none;
        outline: none;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        width: 32px;
        height: 32px;
        cursor: pointer;
        span {
          display: none;
        }
      }
      &--search {
        border-right: 1px solid $grey2;
        padding-right: 50px;
        margin-right: 15px;
        button {
          background-image: url('../../public/assets/img/desktop_menu_search.svg');
        }
      }
      &--save {
        button {
          background-image: url('../../public/assets/img/desktop_menu_save.svg');
        }
      }
      &--user {
        button {
          background-image: url('../../public/assets/img/desktop_menu_user.svg');
        }
        &--logged {
          button {
            background-image: url('../../public/assets/img/desktop_menu_user_logged.svg');
          }
        }
      }

      &--alert {
        button {
          background-image: url('../../public/assets/img/desktop_menu_alert.svg');
        }
      }
    }
  }
}
