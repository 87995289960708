// building_pictures2

.building_pictures2 {
  margin: 0;
  padding: 0;
  position: relative;
  > span {
    margin-bottom: $spacerxs2 !important; // fix for NextJs
  }
  &--overlay {
    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color: $text, $alpha: 0.7);
    }

    & &__info {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin-bottom: 0px !important;

      &__content {
        border: 1px solid $white;
        display: inline-flex;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-15.19deg);
        width: 158px;
        height: 65px;
        text-align: center;

        > div {
          color: $white;
          margin: auto;
        }
      }
    }
  }
  &__picture,
  > span {
    // fix for NextJs
    display: block !important;
    margin: 0 0 $spacerxs2 0 !important;
    padding: 0 !important;
    width: 100% !important;
    min-width: 100% !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }
  .building_pictures2__picture-type2 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  > div.building_pictures2__picture-type2 {
    margin-bottom: 0 !important; // fix for NextJs
  }
  &__auction_created {
    @extend %font-inter-medium;
    position: absolute;
    top: 16px;
    right: 0;
    color: $white;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    background-color: $state6;
    margin: 0;
    padding: 2px $spacerxs2 2px $spacerxxs2;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &__auction_coming_soon {
    @extend .building_pictures__auction_created;
    background-color: $state11;
  }
  &__auction_under_evaluation {
    @extend .building_pictures__auction_created;
    background-color: $state2;
  }
  &__auction_running {
    @extend .building_pictures__auction_created;
    background-color: $state5;
  }
  &__auction_closed {
    @extend .building_pictures__auction_created;
    background-color: $state3;
  }
  &__auction_sold {
    @extend .building_pictures__auction_created;
    background-color: $state3;
  }
  &__only_by_now {
    @extend .building_pictures__auction_created;
    background-color: $state5;
  }
}
