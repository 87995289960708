.client_feedback {
  padding: 30px 0px;
  background-color: $primary;
  &__title {
    @extend %font-spacegrotesk-medium;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.004em;
    color: $white;
  }
  &__form {
    &__question {
      margin-top: 20px;
      &__title {
        margin-bottom: 12px;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.004em;
        color: $white;
      }
      &__two-colums {
        display: flex;
        justify-content: space-between;
        &__item {
          flex: 0 0 auto;
          width: 49%;
        }
      }
      .button_primary {
        border: 1px solid $white;
      }
      .MuiRating-sizeLarge {
        font-size: 2.875rem;
      }
      .MuiRating-root {
        width: 310px;
        justify-content: space-between;
      }
    }
    &__submit {
      text-align: center;
      margin-top: 12px;
      button {
        @extend .button_primary;
        @extend .button_primary--type3;
        width: 40%;
        @include smartphone {
          width: 100%;
        }
      }
    }
  }
}
