.timeline {
  margin-bottom: 20px;
  max-width: 1440px;

  @include only-desktop {
    margin-top: $spacerxl;
  }
  @include tablet {
    padding: 32px 24px;
  }

  &__list {
    padding-bottom: 32px;
    padding-top: 32px;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(300px);
    grid-auto-flow: column;
    @include hide-scrollbar;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
    overflow-x: scroll;
    &:before,
    &:after {
      content: '';
      width: 10px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      scroll-snap-align: center;

      &:hover {
        .timeline__list__item__content {
          background: white;
          box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.02),
            -4px -4px 4px rgba(0, 0, 0, 0.02);
          border-radius: 4px;
        }
        .timeline__list__item__middle {
          color: #2824e5;
        }
        .timeline__list__item__middle__image {
          background: transparent
            url('../../public/assets/img/ico_pin_timeline_blu.svg') no-repeat
            bottom center;
        }
      }

      &:first-child {
        .timeline__list__item__middle__line {
          width: 50%;
          position: relative;
          left: 50%;
        }
      }

      &:last-child {
        .timeline__list__item__middle__line {
          width: 50%;
        }
      }

      &__middle {
        @extend %font-inter-bold;
        text-transform: uppercase;
        color: #8c8a9f;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        margin-top: 144px;
        margin-bottom: 32px;
        &__line {
          padding-top: 16px;
          border-bottom: 1px solid #dddde7;
          margin-bottom: -35px;
        }
        &__image {
          padding: 16px 0px 16px 0px;
          background: transparent
            url('../../public/assets/img/ico_pin_timeline.svg') no-repeat bottom
            center;
          position: relative;
          top: 19px;
        }
      }

      &__content {
        width: 300px;
        display: flex;
        background: rgba(40, 36, 229, 0.1);
        border-radius: 4px;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0px $spacerxs2 0px $spacerxs2;
        font-size: 14px;
        line-height: 24px;
        padding-top: $spacerxs;
        padding-bottom: $spacerxs;
        span::first-letter {
          text-transform: uppercase;
        }
      }

      &:nth-child(even) {
        flex-direction: column-reverse;
        .timeline__list__item__middle {
          margin-top: 24px;
          margin-bottom: 144px;
        }
        .timeline__list__item__content {
          justify-content: flex-end;
        }
      }
      @include smartphone {
        &:nth-child(even) {
          flex-direction: column;
          .timeline__list__item__middle {
            //reset even pattern
            margin-top: 32px;
            margin-bottom: 32px;
          }
        }
        flex-direction: column;
        .timeline__list__item__middle {
          margin-top: 32px;
          margin-bottom: 32px;
          color: #2824e5;
        }
        .timeline__list__item__content {
          justify-content: flex-start;
          text-align: center;
          background: none;
        }
        .timeline__list__item__middle__image {
          background: transparent
            url('../../public/assets/img/ico_pin_timeline_blu.svg') no-repeat
            bottom center;
        }
      }
    }
  }
}
