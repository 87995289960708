// desktop_contact_cta

.desktop_contact_cta {
  @include box-shadow(z2);
  background-color: $white;
  margin: 0 $spacerxs2 $spacers $spacerxs2;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid $grey5;
  border-bottom: 0;
  &__title {
    @extend .title_subheading_xxs;
    height: 28px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: $grey5;
    margin: 0;
    padding: 4px 15px 0 17px;
  }
  &__content {
    padding: $spacerxs2;
    &__info {
      margin: 0;
      padding: 0 0 0 25px;
      @extend .text_xs;
      background-color: transparent;
      background-position: 0 50%;
      background-repeat: no-repeat;
      &--time {
        background-image: url('../../public/assets/img/menu_footer_hour.svg');
        margin-bottom: $spacerxxs;
        color: $text;
      }
      &--tel {
        background-image: url('../../public/assets/img/menu_footer_phone.svg');
        z-index: 10000;
      }
    }
  }
}
