// desktop_auction_info

.desktop_auction_info {
  margin: 0;
  padding: $spacerxs2;
  height: 100px;
  border-bottom-left-radius: 4px;
  &--type2 {
    padding: $spacerxs2 0 $spacerxs2 0;
  }
  &--type3 {
    height: 75px;

    &__preview {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      padding: $spacerxxs2;
    }

    &__info {
      padding: $spacerxxs2 0 $spacerxxs2 0;
    }
  }
  &--auction_running {
    background-color: $background8;
    .countdown_box {
      background-color: transparent;
      margin: 0;
      padding: 0;
    }
  }
  &--auction_created {
    background-color: $background11;
    .countdown_box {
      background-color: transparent;
      margin: 0;
      padding: 0;
    }
  }
  &--auction_buy_now {
    background-color: $background6;
  }
  &__buy_now {
    @extend %font-inter-medium;
    font-size: 14px;
    line-height: 20px;
    color: $grey2;
    margin: 0;
    padding: 0;
    span {
      display: block;
      margin-top: $spacerxs;
      @extend .title_number_s;
    }
  }
  &--auction_closed {
    background-color: $background12;
  }
  &--auction_elaboration {
    background-color: $background13;
  }
  &--auction_coming_soon {
    background-color: $background16;
  }
  &__message {
    margin: 0;
    padding: 0 0 0 20px;
    font-size: 14px;
    text-transform: uppercase;
    color: $state3;
    background-color: transparent;
    background-position: 0 7px;
    background-repeat: no-repeat;
    &--coming_soon {
      background-image: url('../../public/assets/img/ico_alert_blu_small.svg');
      color: $state11;
    }
    &--closed {
      background-image: url('../../public/assets/img/ico_red_alert.svg');
    }
    &--elaboration {
      background-image: url('../../public/assets/img/ico_orange_alert.svg');
      color: $state2;
    }
  }

  &__image-preview {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
  }

  &__image-preview + &__image-preview {
    margin-left: 8px;
  }
}
