/* Global styles */

html {
  //height: 100%; Removed after sticky_footer implementation
  margin: 0;
  padding: 0;
  * {
    box-sizing: border-box;
  }
}
body {
  @extend %font-inter-regular;
  background-color: $white;
  color: $text;
  font-size: 16px;
  line-height: 28px;
  //height: 100%; Removed after sticky_footer implementation
  margin: 0;
  padding: 0;
  strong {
    @extend %font-inter-bold;
  }
  hr {
    display: none;
  }
  #__next {
    height: 100%;
    width: 100%;
  }
  &.with_sticky_footer {
    padding-bottom: $sticky-footer-height;
  }
}

// a: & button behavior
a:link,
a:visited,
button {
  @include transition(0.35s);
  text-decoration: none;
  &:hover,
  &:active {
    text-decoration: none;
  }
}

@mixin button_hover($scale) {
  transition-property: all;
  transition-duration: 0.3s;
  transform: scale($scale);
}

// shadows
@mixin box-shadow($type: z1) {
  @if $type == z1 {
    box-shadow: 0 4px 4px $shadow;
  } @else if $type == z2 {
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.05), -2px 0px 8px rgba(0, 0, 0, 0.05);
  } @else if $type == z3 {
    box-shadow: -4px -4px 4px rgba(0, 0, 0, 0.02), 4px 4px 4px rgba(0, 0, 0, 0.02);
  } @else if $type == z4 {
    box-shadow: -2px -2px 6px rgba(40, 36, 229, 0.05), 2px 2px 6px rgba(40, 36, 229, 0.05);
  } @else if $type == z5 {
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.05);
  } @else if $type == z6 {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05), -2px -2px 4px rgba(0, 0, 0, 0.05);
  }
}
