// auction_caution

.auction_caution {
  background-color: $white;
  padding: $spacerxs2;
  &__title {
    @extend .title_heading_s;
    margin: 0 0 $spacerxs2 0;
    padding: 0;
  }
  &__text {
    @extend .title_subheading_xxs;
    margin: 0 0 $spacers 0;
    padding: 0;
    color: $text;
  }
  &__subtitle {
    @extend .title_subheading_xs;
    color: #8c8b9f;
    margin: 0;
    padding: 0;
  }
  &__info {
    @extend .button_tertiary;
    @extend .button_ico;
    @extend .button_ico--info_blu;
  }
  &__value {
    @extend .title_number_s;
  }
  &__warning {
    background-color: $background1;
    background-repeat: no-repeat;
    background-position: $spacerxs2 $spacerxs2;
    background-image: url('../../public/assets/img/ico_alert_blu.svg');
    margin: 0 0 $spacerxs2 0;
    padding: $spacerxs $spacerxs2 $spacerxs2 $spacerxxl;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.06em;
    color: $secondary;
  }
  &__payment_mode {
    border: 1px solid $grey3;
    border-radius: 4px;
    margin-bottom: $spacerxs2;
    padding: $spacerxxs2 $spacerxs2;
  }
  .mandate-acceptance {
    color: $secondary;
    @extend .text_xxs;
    line-height: 14.5px;
    background-color: $grey6;
    padding: $spacerxs;
    height: 100px;
    overflow: hidden;
    &.expanded {
      height: auto;
    }
  }
  .mandate-acceptance-expander {
    font-size: 12px;
    line-height: 24px;
    color: $primary;
    text-decoration: underline;
    background-color: $grey6;
    margin: 0;
    padding: 0 0 $spacerxxs $spacerxs;
    letter-spacing: 1px;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
}
