.horizontal_slider {
  &__row_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &--header_padding {
      padding: 0 128px;
      @include m-desktop {
        padding: 0 $spacerxxl;
      }
      @include tablet {
        padding: 0 $spacers;
      }
    }

    @include smartphone {
      align-items: flex-end;
    }

    &__controls {
      padding-left: 0;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 25px;
      &__arrow {
        list-style: none;
        button {
          outline: none;
          border: 2px solid #f5f5ff;
          padding: 16px;
          display: block;
          cursor: pointer;
          width: 32px;
          height: 32px;
          background-color: #ffffff;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: 8px;
          border-radius: 4px;

          &:disabled {
            cursor: default;
            opacity: 0.5;
          }

          &:hover {
            background-size: 10px;
            transition: background-size 0.2s ease-in-out;
          }
          span {
            display: none;
          }
        }
        &--prev {
          background-image: url('../../public/assets/img/carousel_arrow_left.svg');
          background-repeat: no-repeat;
        }
        &--next {
          background-image: url('../../public/assets/img/carousel_arrow_right.svg');
          background-repeat: no-repeat;
          margin-left: 8px;
          margin-right: 0px;
        }
      }

      @include tablet {
        display: none;
      }
    }
    &__title {
      @extend .title_heading_l;
      padding: 0;
      @include smartphone {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}
