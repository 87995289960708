.valutatore {
  &__host {
    padding: 64px 0px;
    background-image: url('../../public/assets/img/home/cover_blockvalutatore.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-size: cover;
    min-height: 70vh;

    &__container {
      @include tablet {
        display: flex;
        align-items: center;
        padding: 16px;
      }

      &__back {
        font-size: 48px;
        color: #c9c9d3;
        margin-right: 26px;
        cursor: pointer;
      }

      &__title {
        @extend %font-spacegrotesk-medium;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;

        /* or 133% */
        text-align: center;
        letter-spacing: 0.004em;

        /* Base/White */
        color: #ffffff;
      }

      &__subtitle {
        @extend %font-inter-regular;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.006em;
        margin-bottom: 16px;

        /* Base/White */
        color: #ffffff;
        @include tablet {
          text-align: left;
          /* Neutral/900 */
          color: #131223;
          padding-top: 24px;
        }
      }
      &__subtitle-ext2 {
        @extend %font-inter-regular;
        color: #ffffff;

        margin-bottom: 16px;
      }
    }
  }

  &__title {
    @extend %font-spacegrotesk-medium;
    white-space: pre-line;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;

    &--success {
      white-space: pre-line;
      @include tablet {
        font-size: 40px;
      }
    }

    /* or 58px */
    text-align: center;
    letter-spacing: 0.004em;

    /* Base/White */
    color: #ffffff;

    @include smartphone {
      text-align: left;
    }
  }

  &__subtitle {
    @extend %font-inter-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;

    /* or 160% */
    text-align: center;
    letter-spacing: 0.006em;

    /* Base/White */
    color: #ffffff;
    margin-top: 24px;
    margin-bottom: 16px;

    @include smartphone {
      text-align: left;
    }
  }

  &__white_btn {
    @extend %font-inter-regular;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.006em;
    text-transform: uppercase;
    background: #ffffff;
    padding: 11px 16px;
    border: 1px solid #ffffff;
    border-radius: 4px;
    margin-top: 24px;
    margin-bottom: 40px;
    color: #d92792;
    width: 288px;
    cursor: pointer;
    @include smartphone {
      width: 100%;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    max-width: 805px;
    background: #ffffff;
    border-radius: 4px;
    margin: 32px auto;
    padding: 48px;

    &--noBg {
      background: none;
    }

    @include tablet {
      max-width: 100%;
      margin: 0 auto;
      padding: 16px;
    }

    * > select,
    * > input {
      width: 343px;
      @include smartphone {
        width: 100%;
      }
    }

    &__button_forward {
      margin-top: 32px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 11px 16px;
      gap: 8px;
      cursor: pointer;
      width: 343px;
      height: 48px;

      /* Interactive/Blue light/700 */
      @extend %font-inter-medium;
      background-color: #2824e5;
      border-radius: 4px;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;

      /* identical to box height, or 162% */
      text-align: center;
      letter-spacing: 0.12em;
      text-transform: uppercase;

      /* Base/White */
      color: #ffffff;

      @include smartphone {
        width: 100%;
        height: 48px;
        padding: 11px 16px;
      }

      &--accent {
        background-color: $accent;
        border: none;
      }
    }

    &__button_back {
      /* Interactive/Button/M */
      margin-top: 24px;
      @extend %font-inter-medium;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;

      /* identical to box height, or 171% */
      letter-spacing: 0.1em;
      text-transform: uppercase;

      /* Neutral/300 */
      cursor: pointer;
      color: #c9c9d3;
    }

    &--two_columns {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      @include tablet {
        grid-template-columns: 1fr;
      }
    }
    &--other {
      width: 100%;
      display: grid;
      grid-template-columns: 66% 33%;
      gap: 16px;
      margin-bottom: 16px;

      &__inputs {
        display: flex;
        align-items: center;
        margin-left: -12px;
      }

      .login__form__field__row {
        margin-bottom: 0px;
        .input_style1 {
          width: calc(100% - 16px);
        }
      }
    }
    &--three_btns {
      display: grid;
      margin-bottom: 16px;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
      @include tablet {
        margin-right: 0px;
        width: 100%;
        gap: 16px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        div {
          &:nth-child(1) {
            grid-area: 1 / 1 / 2 / 2;
          }
          &:nth-child(2) {
            grid-area: 1 / 2 / 2 / 3;
          }
          &:nth-child(3) {
            grid-area: 2 / 1 / 3 / 3;
          }
        }
      }
    }
    &__title {
      /* Display/M */
      @extend %font-spacegrotesk-bold;
      color: #d92792;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &__subtitle {
      @extend %font-inter-medium;
      color: #131223;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
    &__choiche {
      padding: 11px 8px;
      gap: 8px;
      border-radius: 4px;
      background: #ffffff;
      border: 1px solid #dddde7;
      border-radius: 4px;
      @extend %font-inter-medium;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;

      /* identical to box height, or 171% */
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      /* Interactive/Blue light/700 */
      color: #2824e5;

      &:hover {
        background: #bed8f7;
      }

      &--selected {
        background: #bed8f7;
      }
    }

    &__checkboxes {
      margin-left: -12px;
    }
  }
}
