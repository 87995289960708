// login

.login {
  background: $grey6 url('../../public/assets/img/modal_bg.svg') repeat 0 0;
  padding: $spacers $spacerxs2;
  &__title {
    @extend .title_heading_m;
    margin: 0 0 $spacers 0;
    padding: 0;
  }
  &__form {
    @include box-shadow(z2);
    background-color: $white;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    &__selector {
      border: none;
      margin: 0;
      padding: $spacerxs2;
      background-color: $background6;
      &--active {
        background-color: $white;
      }
    }
    &__field {
      border: none;
      margin: 0;
      padding: $spacerxs2;
      &__row {
        $self: &;
        margin-bottom: $spacers;
        &--no_margin_bottom {
          margin-bottom: 0;
          clear: left;
          .MuiButtonBase-root {
            float: left;
          }
          #{$self}__label {
            @extend %font-inter-regular;
            margin-bottom: $spacerxs;
            padding-top: 10px;
            margin-left: 45px;
          }
        }
        &__label {
          @extend .title_heading_xxs;
          display: block;
          margin-bottom: $spacerxxs2;
        }
        input[type='text'],
        input[type='date'],
        input[type='password'],
        input[type='email'] {
          width: 100%;
        }
        &__button {
          @extend .button_primary;
          width: 100%;
        }
        &__forgot_password {
          font-size: 14px;
          line-height: 24px;
          text-align: right;
          letter-spacing: 0.04em;
        }
        &__text {
          @extend .text_xs;
          margin-bottom: $spacers;
        }
        &__info {
          @extend .text_xs;
          line-height: 24px;
          margin-bottom: $spacerxs;
          display: block;
        }
        .MuiFormGroup-root {
          .MuiFormControlLabel-root {
            margin-left: 0px;
          }
        }
      }
      &__policy_block {
        margin-bottom: $spacers;
        label {
          display: block;
          font-size: 16px;
          line-height: 26px;
          letter-spacing: 0.5px;
          margin: 0;
          padding: $spacerxxs2 0 0 $spacerxl;
        }
        .MuiCheckbox-root {
          float: left;
        }
      }
      &__button {
        @extend .button_primary;
        margin-top: $spacers;
        width: 100%;
      }
    }
  }
}
